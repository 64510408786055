import { useEffect } from "react";
import { withRouter } from "react-router-dom"

const Logout = withRouter(({ history }) => {

  useEffect(() => {
    localStorage.removeItem('user-details')
    localStorage.removeItem('user-info')

    history.push('/login')
  }, [history]);

  return null
})

export default Logout;
