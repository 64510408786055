import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails } from "../helpers";
import { Bar } from "react-chartjs-2";
import { Section, SectionTitle } from "../ui/Section";
import { Card, CardBody } from "../ui/Card";

import "../App.css";
import { LeadText } from "../ui/LeadText";

function CmpVisitors({ CurrentProject }) {
  const [mounted, setMounted] = useState(false);
  const [visitorsData, setVisitors] = useState([]);

  useEffect(() => {
    if (CurrentProject.tiles["tracking_etracker"]) {
      getEtrackerVisitors();
    } else if (CurrentProject.projecttiles && CurrentProject.projecttiles.tracking_etracker) {
      getVisitors();
    }
  }, [CurrentProject]);

  function getVisitors() {
    if (getUserDetails()) {
      fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/visitors",
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (data) {
          setVisitors(data.data);
          setMounted(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  function getEtrackerVisitors() {
    if (getUserDetails()) {
      fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/etracker-visitors",
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (data) {
          setVisitors(data.data);
          setMounted(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  var labelArray = [];
  var dataArray = [];
  const chart = {
    labels: labelArray,
    datasets: [
      {
        label: "Visitors",
        backgroundColor: "#001bbe",
        borderWidth: 0,
        data: dataArray,
      },
    ],
  };

  if (mounted) {
    for (var ii = 0; ii < visitorsData.length; ii++) {
      labelArray.push(
        "KW " +
          visitorsData[ii].date.substring(4, 6) +
          " - " +
          visitorsData[ii].date.substring(0, 4)
      );
      dataArray.push(visitorsData[ii].count);
    }
  }

  return (
    <div className="">
      {mounted ? (
        <Section>
          <SectionTitle>Besucherzahlen</SectionTitle>
          <LeadText>
            Das Diagramm zeigt die Besucherzahlen der Webseite in den
            vergangenen Wochen. Unregelmäßigkeiten könnten durch Bots in
            diversen Wochen verursacht werden.
          </LeadText>
          <Card className="">
            <CardBody>
              <Bar data={chart} options={{ responsive: true }} />
            </CardBody>
          </Card>
        </Section>
      ) : (
        <div>
          <Section>Loading...</Section>
        </div>
      )}
    </div>
  );
}

export default CmpVisitors;
