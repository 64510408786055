export function Label(props) {

  const { children } = props
  
  return (
    <label
      className="block tracking-wide text-gray-700 font-bold mb-2"
      { ...props }
    >
      { children }
    </label>
  )
}
