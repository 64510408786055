import { Container } from "../../ui/Container";
import { Section } from "../../ui/Section";
import { Button } from "../../ui/Button";
import { CardBody } from "../../ui/Card";
import { Modal, ModalHeader } from "../../ui/Modal";
import React, { useState, useEffect } from "react";
import { fetchData, getRole, getUserDetails } from "../../helpers";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "../../ui/Alert";
import { useTable, useSortBy } from "react-table";
import {
  faSortDown,
  faSortUp,
  faSort,
  faTrashAlt,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

function DigitalstrategienZuordnung() {
  const [mountedDS, setMountedDS] = useState(false);
  const [mountedP, setMountedP] = useState(false);
  const [mountedPDS, setMountedPDS] = useState(false);
  const [roleData, setRole] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [
    stateProjectDigitalstrategyProjectID,
    setStateProjectDigitalstrategyProjectID,
  ] = useState("1");
  const [stateProjectDigitalstrategyStep, setStateProjectDigitalstrategyStep] =
    useState("1");
  const [
    stateProjectDigitalstrategyDigitalstrategyID,
    setStateProjectDigitalstrategyDigitalstrategyID,
  ] = useState("1");
  const [
    stateProjectDigitalstrategyStatus,
    setStateProjectDigitalstrategyStatus,
  ] = useState("todo");
  const [digitalstrategiesData, setDigitalstrategiesData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [projectDigitalstrategiesData, setProjectDigitalstrategiesData] =
    useState([]);
  const [errorList, setErrorList] = useState(null);
  const [errorState, setErrorState] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Projekt",
        accessor: "project_id",
      },
      {
        Header: "Schritt",
        accessor: "step",
      },
      {
        Header: "Digitalstrategie",
        accessor: "digitalstrategy_id",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "",
        accessor: "delete",
      },
    ],
    []
  );

  const [searchTerm, setSearchTerm] = useState("");

  const onProjectMenuOpen = () => setIsProjectMenuOpen(true);
  const onProjectMenuClose = () => setIsProjectMenuOpen(false);
  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
  const onDigtalstrategyMenuOpen = () => setIsDigtalstrategyMenuOpen(true);
  const onDigtalstrategyMenuClose = () => setIsDigtalstrategyMenuOpen(false);
  const [isDigtalstrategyMenuOpen, setIsDigtalstrategyMenuOpen] =
    useState(false);

  useEffect(() => {
    try {
      getRole().then((result) => setRole(result));
      getDigitalstrategies();
      getProjects();
      getProjectDigitalstrategies();
    } catch {
      setErrorState(true);
    }
  }, []);

  async function getDigitalstrategies() {
    var dData = [];
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/digitalstrategies", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          for (var ii = 0; ii < data.length; ii++) {
            dData.push(data[ii]);
          }
          setDigitalstrategiesData(dData);
          var firstID = dData[0]["id"];
          setStateProjectDigitalstrategyDigitalstrategyID(firstID);
          setMountedDS(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }
  async function getProjects() {
    var pData = [];
    if (localStorage.getItem("user-info")) {
      await fetchData(process.env.REACT_APP_API_URL + "/projects", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          for (var ii = 0; ii < data.data.length; ii++) {
            pData.push(data.data[ii]);
          }
          setProjectsData(pData);
          var firstID = pData[0]["id"];
          setStateProjectDigitalstrategyProjectID(firstID);
          setMountedP(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  async function getProjectDigitalstrategies() {
    if (getUserDetails()) {
      await fetchData(
        process.env.REACT_APP_API_URL + "/projectdigitalstrategies",
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (data) {
          setProjectDigitalstrategiesData(data.data);
          setMountedPDS(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  //post ProjectDigitalstrategy function
  function postProjectDigitalstrategy(event) {
    event.preventDefault();

    var projectDigitalstrategyObj = {};
    projectDigitalstrategyObj.project_id = stateProjectDigitalstrategyProjectID;
    projectDigitalstrategyObj.step = stateProjectDigitalstrategyStep;
    projectDigitalstrategyObj.digitalstrategy_id =
      stateProjectDigitalstrategyDigitalstrategyID;
    projectDigitalstrategyObj.status = stateProjectDigitalstrategyStatus;

    fetchData(process.env.REACT_APP_API_URL + "/projectdigitalstrategies", {
      method: "POST",
      body: JSON.stringify(projectDigitalstrategyObj),
    })
      .then((response) => response.json())
      .then(function (data) {
        if (data.ok) {
          setErrorList(null);
          return data;
        }
        if (data.success) {
          setErrorList(null);
        }
        if (data.message === "project_digitalstrategy step already defined") {
          setErrorList(
            "Das Projekt besitzt bereits eine Digitalstrategie mit dem ausgewählten Schritt."
          );
        }
      })
      .then((response) => getProjectDigitalstrategies())
      .catch((error) => {
        alert(error);
      });
  }

  //logic to display userDigitalstrategies
  const projectsDropdownData = projectsData.map((project, id) => {
    return {
      label: project.name,
      value: project.id,
    };
  });

  const digitalstrategiesDropdownData = digitalstrategiesData.map(
    (digitalstrategy, id) => {
      return {
        label: digitalstrategy.title,
        value: digitalstrategy.id,
      };
    }
  );

  function handleSelectChangePOSTproject(event) {
    setStateProjectDigitalstrategyProjectID(event.value);
  }

  function handleSelectChangePOSTstep(event) {
    setStateProjectDigitalstrategyStep(event.target.value);
  }

  function handleSelectChangePOSTdigitalstrategy(event) {
    setStateProjectDigitalstrategyDigitalstrategyID(event.value);
  }

  function handleSelectChangePOSTstatus(event) {
    setStateProjectDigitalstrategyStatus(event.target.value);
  }

  function handleSelectChangeProjectDigitalstrategyStep(event) {
    var value = event.target.value;
    var id = event.target.id.split("-")[2];

    console.log(id, value);
    onSave(id, "step", value);
  }

  function listenerStateProjectDigitalstrategyStatus(status) {
    return status;
  }

  function handleSelectChangeProjectDigitalstrategyStatus(event) {
    var strId = event.target.id;
    var value = event.target.value;
    var id = strId.slice(strId.lastIndexOf("-") + 1);

    onSave(id, "status", value);
  }

  //update projectdigitalstrategy
  function onSave(id, field, value) {
    var projectdigitalstrategyObj = {};
    if (field === "status") {
      projectdigitalstrategyObj.status = value;
    } else if (field === "step") {
      projectdigitalstrategyObj.step = value;
    }

    fetchData(
      process.env.REACT_APP_API_URL + "/projectdigitalstrategies/" + id,
      {
        method: "PUT",
        body: JSON.stringify(projectdigitalstrategyObj),
      }
    )
      .then((response) => response.json())
      .then((response) => getProjectDigitalstrategies());
  }

  function onDelete(id) {
    fetchData(
      process.env.REACT_APP_API_URL + "/projectdigitalstrategies/" + id,
      {
        method: "DELETE",
      }
    )
      .then((response) => response.json())
      .then((response) => getProjectDigitalstrategies());
  }

  const data = React.useMemo(() => projectDigitalstrategiesData);

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
          initialState: {
            sortBy: [
              {
                id: "project_id",
                desc: false,
              },
              {
                id: "step",
                desc: false,
              },
            ],
          },
        },
        useSortBy
      );

    return (
      <>
        {mountedDS && mountedP && mountedPDS ? (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <>
                      {column.Header === "Projekt" ? (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="w-3/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
                        >
                          {column.render("Header")}
                          {}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className=""
                                />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} className="" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="" />
                            )}
                          </span>
                        </th>
                      ) : (
                        <></>
                      )}
                      {column.Header === "Schritt" ? (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="w-2/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
                        >
                          {column.render("Header")}
                          {}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className=""
                                />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} className="" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="" />
                            )}
                          </span>
                        </th>
                      ) : (
                        <></>
                      )}
                      {column.Header === "Digitalstrategie" ? (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="w-3/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
                        >
                          {column.render("Header")}
                          {}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className=""
                                />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} className="" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="" />
                            )}
                          </span>
                        </th>
                      ) : (
                        <></>
                      )}
                      {column.Header === "Status" ? (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="w-3/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
                        >
                          {column.render("Header")}
                          {}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  className=""
                                />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} className="" />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} className="" />
                            )}
                          </span>
                        </th>
                      ) : (
                        <></>
                      )}
                      {column.Header === "" ? (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className="w-1/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
                        >
                          {column.render("Header")}
                          {}
                          <span></span>
                        </th>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows
                .filter((itemData) => {
                  if (searchTerm == "") {
                    return itemData;
                  } else if (
                    projectsData
                      .filter((p) => p.id === itemData.original.project_id)
                      .map((p) => p.name)
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    digitalstrategiesData
                      .filter(
                        (p) => p.id === itemData.original.digitalstrategy_id
                      )
                      .map((p) => p.title)
                      .toString()
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return itemData;
                  }
                })
                .map((row, i) => {
                  prepareRow(row);
                  var projectDigitalstrategy_id = row.original.id;
                  return (
                    <tr
                      {...row.getRowProps()}
                      key={
                        "project-digitalstrategies-table-row-" +
                        projectDigitalstrategy_id
                      }
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            key={
                              "project-digitalstrategies-table-row-" +
                              projectDigitalstrategy_id +
                              "cell-" +
                              cell.column.id
                            }
                            className="px-6 py-4 whitespace-nowrap"
                          >
                            {cell.column.Header === "Projekt" ? (
                              projectsData
                                .filter((p) => p.id === cell.value)
                                .map((p) => p.name)
                            ) : (
                              <></>
                            )}
                            {cell.column.Header === "Schritt" ? (
                              <input
                                className="block appearance-none w-full  border-none bg-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                                id={
                                  "projectdigitalstrategy-status-" +
                                  projectDigitalstrategy_id
                                }
                                name="step"
                                placeholder="Schritt"
                                defaultValue={cell.value}
                                onBlur={
                                  handleSelectChangeProjectDigitalstrategyStep
                                }
                              />
                            ) : (
                              <></>
                            )}
                            {cell.column.Header === "Digitalstrategie" ? (
                              digitalstrategiesData
                                .filter((ds) => ds.id === cell.value)
                                .map((ds) => ds.title)
                            ) : (
                              <></>
                            )}
                            {cell.column.Header === "Status" ? (
                              <select
                                id={
                                  "projectdigitalstrategy-status-" +
                                  projectDigitalstrategy_id
                                }
                                className="block appearance-none w-full  border-none bg-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                                onSelect={
                                  listenerStateProjectDigitalstrategyStatus
                                }
                                onChange={
                                  handleSelectChangeProjectDigitalstrategyStatus
                                }
                              >
                                {cell.value === "todo" ? (
                                  <>
                                    <option value="todo">To Do</option>
                                    <option value="done">Done</option>
                                  </>
                                ) : (
                                  <>
                                    <option value="done">Done</option>
                                    <option value="todo">To Do</option>
                                  </>
                                )}
                              </select>
                            ) : (
                              <></>
                            )}
                            {cell.column.Header === "" ? (
                              <Button
                                onClick={() =>
                                  onDelete(projectDigitalstrategy_id)
                                }
                                className=""
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className=""
                                />
                              </Button>
                            ) : (
                              <></>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <div>
      {errorState ? (
        <Section>
          <Container>
            <Alert type="secondary">
              Serverfehler! Etwas ist schief gelaufen. Informieren Sie einen
              Entwickler.
            </Alert>
          </Container>
        </Section>
      ) : (
        <div>
          {roleData === "admin" ? (
            <div className="">
              {mountedDS && mountedP && mountedPDS ? (
                <Section>
                  <Container>
                    <Modal open={isOpen} className="">
                      <ModalHeader
                        heading="Neue Zuordnung"
                        onClose={() => setIsOpen(false)}
                      />
                      <CardBody className="">
                        {errorList !== null ? (
                          <div className="text-red-900 bg-red-200 rounded-sm -m-0.5 px-4">
                            <span className="">
                              Der Schritt wurde bereits für das gewählte
                              <br />
                              Projekt gesetzt.
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                        <form>
                          <div className="grid gap-4 w-96 mb-4">
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="project"
                              >
                                Projekt
                              </label>
                              <Select
                                onMenuOpen={onProjectMenuOpen}
                                onMenuClose={onProjectMenuClose}
                                options={projectsDropdownData}
                                onChange={handleSelectChangePOSTproject}
                                placeholder="Projekt..."
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="step"
                              >
                                Schritt
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                name="step"
                                type="number"
                                step="1"
                                defaultValue={stateProjectDigitalstrategyStep}
                                selected={stateProjectDigitalstrategyStep}
                                onChange={handleSelectChangePOSTstep}
                              ></input>
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="digitalstrategy"
                              >
                                Digitalstrategie
                              </label>
                              <Select
                                onMenuOpen={onDigtalstrategyMenuOpen}
                                onMenuClose={onDigtalstrategyMenuClose}
                                options={digitalstrategiesDropdownData}
                                onChange={handleSelectChangePOSTdigitalstrategy}
                                placeholder="Projekt..."
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="status"
                              >
                                Status
                              </label>
                              <select
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                name="status"
                                defaultValue={stateProjectDigitalstrategyStatus}
                                selected={stateProjectDigitalstrategyStatus}
                                onChange={handleSelectChangePOSTstatus}
                              >
                                <option value="todo">To Do</option>
                                <option value="done">Done</option>
                              </select>
                            </div>
                          </div>
                          <Button
                            variant="primary"
                            type=""
                            className=""
                            onClick={postProjectDigitalstrategy}
                          >
                            Zuordnung anlegen
                          </Button>
                        </form>
                      </CardBody>
                    </Modal>
                    <div className="float">
                      <div className="float-left w-10/12">
                        <h1 className="text-4xl font-bold mb-8 ">
                          Digitalstrategien - Zuordnung&nbsp;
                        </h1>
                      </div>
                      <div className="float-right w-2/12 mb-8">
                        <Button onClick={() => setIsOpen(true)} className="">
                          <FontAwesomeIcon icon={faPlus} className="" />
                        </Button>
                      </div>
                      <div className="float-right w-2/12">
                        <input
                          type="text"
                          className="text-hpl bg-hpl_bg hover:bg-gray-300 hover:text-gray-800 border-none rounded w-full"
                          placeholder="Suche..."
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="float-left w-10/12">
                      <span className="text-xl font-bold mb-8 ">
                        Neue Sortierung mit einem Linksklick auf die Pfeile.{" "}
                        <br />
                        Zusätzliche Sortierungen mit Umschalt + Linksklick auf
                        weitere Pfeile.
                      </span>
                    </div>

                    <Table columns={columns} data={data} />
                  </Container>
                </Section>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          ) : (
            <Section>
              <Container>
                <Alert type="secondary">
                  Auf diese Ansicht haben Sie keinen Zugriff!
                </Alert>
              </Container>
            </Section>
          )}
        </div>
      )}
    </div>
  );
}

export default DigitalstrategienZuordnung;
