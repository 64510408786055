import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Container } from "../ui/Container";
import { SectionTitle } from "../ui/Section";
import { AddedValueCard } from "../ui/AddedValueCard";
import CmpAddedValueGraphEtracker from "./CmpAddedValueGraphEtracker";

import "../App.css";
import "tippy.js/dist/tippy.css";

function CmpAddedValueEtracker({ CurrentProject, startDate, endDate }) {
  const [mounted, setMounted] = useState(false);
  const [mountedP, setMountedP] = useState(false);
  const [mountedT, setMountedT] = useState(false);
  const [mountedE, setMountedE] = useState(false);
  var currentDate = new Date().toISOString().substr(0, 10);

  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const [addedValueDataTotal, setAddedValueDataTotal] = useState({});
  const [addedValuesDataPeriod, setAddedValuesDataPeriod] = useState({});
  const [etrackerTrackings, setEtrackerTrackings] = useState([]);

  const [customPrices] = useState(CurrentProject.custom_prices);

  const [resultAddedValuePeriod, setResultAddedValuePeriod] = useState(0);
  const [resultAddedValueTotal, setResultAddedValueTotal] = useState(0);

  const publication_date = CurrentProject.publication_date;

  useEffect(() => {
    setMounted(false);
    getEtrackerTrackings();
    getEtrackerAddedValuesPeriod(startDate, endDate);
    getEtrackerAddedValuesTotal();

    setMounted(true);
  }, [startDate, endDate]);

  var projecttiles = CurrentProject.tiles;

  async function getEtrackerTrackings() {
    if (localStorage.getItem("user-info")) {
      await fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/etrackings",
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (response) {
          var res = [];
          if (Array.isArray(response.data)) {
            response.data.forEach((tracking) => {
              var key = tracking.id;
              delete tracking.id;
              res[key] = tracking;
            });
          } else {
            var tracking = response.data[Object.keys(response.data)[0]];
            var key = tracking.id;
            delete tracking.id;
            res[key] = tracking;
          }

          setEtrackerTrackings(res);
          setMountedE(true);
        });
    }
  }

  async function getEtrackerAddedValuesPeriod(startDate, endDate) {
    if (localStorage.getItem("user-info")) {
      await fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/etrackerData?start=" +
          startDate +
          "&end=" +
          endDate,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (response) {
          setAddedValuesDataPeriod(response.data);
          var periodResult = 0;
          Object.entries(response.data).forEach((addedValue) => {
            periodResult =
              periodResult +
              addedValue[1] *
                customPrices[
                  "etracker_tracking-" + addedValue[0].split("-")[0]
                ];
          });

          setResultAddedValuePeriod(periodResult);
          setMountedP(true);
        });
    }
  }

  async function getEtrackerAddedValuesTotal() {
    if (localStorage.getItem("user-info")) {
      await fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/etrackerData?start=" +
          CurrentProject.publication_date +
          "&end=" +
          currentDate,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (response) {
          setAddedValueDataTotal(response.data);
          var totalResult = 0;
          Object.entries(response.data).forEach((addedValue) => {
            totalResult =
              totalResult +
              addedValue[1] *
                customPrices[
                  "etracker_tracking-" + addedValue[0].split("-")[0]
                ];
          });
          setResultAddedValueTotal(totalResult);
          setMountedT(true);
        });
    }
  }

  const etrackerAddedValueTiles = Object.entries(addedValuesDataPeriod).map(
    (etrackerData) => {
      var key = etrackerData[0].split("-");
      return (
        <div className="" key={"custom-tracking-tile-" + key[0]}>
          <AddedValueCard
            tippy="Das individuelle Tracking gibt Ihnen einen Einblick über
                  laufende Kampagnen."
            icon={"etracker"}
            value={etrackerData[1]}
            headline={etrackerTrackings[key[0]].headline}
            unitText="Festgelegter Preis pro getracktem Ereignis:"
            price={customPrices["etracker_tracking-" + key[0]]}
            definition={customPrices["DESCR:etracker_tracking-" + key[0]]}
          />
        </div>
      );
    }
  );

  return (
    <div className="mb-8">
      {mounted && mountedP && mountedT && mountedE ? (
        <div>
          <SectionTitle>
            ... haben wir diesen Mehrwert für Sie realisiert und über Etracker
            ausgewertet
          </SectionTitle>
          <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 mb-8">
            {etrackerAddedValueTiles}
          </div>
          <SectionTitle>
            Ihre Webseite hat im Zeitraum vom&nbsp;
            {startDate.substr(8, 2) +
              ". " +
              monthNames[parseInt(startDate.substr(5, 2) - 1)] +
              " " +
              startDate.substr(0, 4)}
            &nbsp;bis&nbsp;
            {endDate.substr(8, 2) +
              ". " +
              monthNames[parseInt(endDate.substr(5, 2)) - 1] +
              " " +
              endDate.substr(0, 4)}
            &nbsp;
            <span className="text-hpl underline">
              {(
                Math.round(parseFloat(resultAddedValuePeriod) * 100) / 100
              ).toFixed(2)}
              &euro;
            </span>{" "}
            erwirtschaftet. Seit Start des Projekts haben Sie{" "}
            <span className="text-hpl underline">
              {(Math.round(resultAddedValueTotal * 100) / 100).toFixed(2)}
              &euro;
            </span>{" "}
            erwirtschaftet.
          </SectionTitle>

          <CmpAddedValueGraphEtracker CurrentProject={CurrentProject} />
        </div>
      ) : (
        <div>
          <Container>Loading...</Container>
        </div>
      )}
    </div>
  );
}
export default CmpAddedValueEtracker;
