import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails, getRole } from "../../helpers";
import { Section } from "../../ui/Section";
import { Container } from "../../ui/Container";
import { Button } from "../../ui/Button";
import { Modal, ModalHeader } from "../../ui/Modal";
import { CardBody } from "../../ui/Card";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

function CmpProductAdmin() {
  const history = useHistory();
  var id = window.location.pathname.split("/")[2];
  const [mounted, setMounted] = useState(false);
  const [roleData, setRole] = useState([]);
  const [product, setProduct] = useState([]);
  const [stateProductTitle, setStateProductTitle] = useState(product.title);
  const [stateProductCategory, setStateProductCategory] = useState(
    product.category
  );
  const [isOpen, setIsOpen] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    getRole().then((result) => setRole(result));
    getProduct();

    async function getProduct() {
      if (getUserDetails()) {
        var res = await fetchData(
          process.env.REACT_APP_API_URL + "/products/" + id,
          {
            method: "GET",
          }
        )
          .then((data) => data.json())
          .catch((error) => {
            console.log("req failed", error);
          });
        setProduct(res.data);
        const content = convertFromRaw(JSON.parse(res.data.content));
        const contentState = EditorState.createWithContent(content);
        setEditorState(contentState);
        setMounted(true);
      }
    }
  }, [id]);

  //update product function
  function onSave(event) {
    event.preventDefault();

    var content = convertToRaw(editorState.getCurrentContent());

    var productObj = {};
    productObj.title = stateProductTitle;
    productObj.category = stateProductCategory;
    productObj.content = JSON.stringify(content);

    fetchData(process.env.REACT_APP_API_URL + "/products/" + product.id, {
      method: "PUT",
      body: JSON.stringify(productObj),
    })
      .then((response) => response.json())
      .then((response) => event.preventDefault());
  }

  function onDelete() {
    fetchData(process.env.REACT_APP_API_URL + "/products/" + product.id, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((response) => "Deleted!" + response);

    history.push("/produkte");
  }

  return (
    <div className="">
      {roleData === "admin" ? (
        mounted ? (
          <Section>
            <Container>
              <Modal open={isOpen} className="p-3">
                <ModalHeader
                  heading="Dieses Angebot löschen? &nbsp;"
                  onClose={() => setIsOpen(false)}
                />

                <CardBody className="">
                  <Button variant="primary" className="" onClick={onDelete}>
                    Angebot löschen
                  </Button>
                </CardBody>
              </Modal>
              <div className="flex w-full mb-8">
                <h1 className="w-10/12 text-4xl font-bold">
                  {product.title}&nbsp;
                </h1>{" "}
                <div className="flex-auto w-2/12 p-0 font-bold">
                  <div className="float-right w-full">
                    <Button
                      variant="primary"
                      type=""
                      className="float-right"
                      onClick={() => setIsOpen(true)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="" />
                    </Button>
                  </div>
                </div>
              </div>
              <form>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="title"
                    >
                      Titel
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight"
                      name="title"
                      type="title"
                      placeholder={product.title}
                      defaultValue={product.title}
                      onChange={(e) => setStateProductTitle(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="category"
                    >
                      Kategorie
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight"
                      name="category"
                      type="text"
                      placeholder={product.category}
                      defaultValue={product.category}
                      onChange={(e) => setStateProductCategory(e.target.value)}
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="content"
                  >
                    Inhalt
                  </label>
                  <div className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight">
                    <Editor
                      id="productContent"
                      value={editorState}
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
                <div className="mt-4 mb-4">
                  <hr />
                </div>
                <Button variant="primary" type="" className="" onClick={onSave}>
                  Speichern
                </Button>
              </form>
            </Container>
          </Section>
        ) : (
          <div>
            <Container>Loading...</Container>
          </div>
        )
      ) : (
        <>
          <h1>Auf diese Ansicht haben Sie keinen Zugriff!</h1>
        </>
      )}
    </div>
  );
}

export default CmpProductAdmin;
