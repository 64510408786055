import { Route, Switch, useRouteMatch } from "react-router-dom"
import CmpCustomerAdmin from "../components/admin/CmpCustomerAdmin"
import Bewertungsseiten from "../pages/admin/Bewertungsseiten"
import Dokumente from "../pages/admin/Dokumente"
import Vereine from "../pages/admin/Vereine"
import Protected from "../Protected"
import { Nav, NavItem } from "../ui/Nav"
import { PageHeader, PageTitle } from "../ui/Page"

export function CustomerDetail() {
	let { path, url } = useRouteMatch();

	return <>
		<PageHeader>
			<PageTitle>Kunde bearbeiten</PageTitle>
			<p className="mb-4">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Optio neque laudantium debitis repudiandae dignissimos, eius quos consectetur odio deleniti. Enim, officia ex facere eaque officiis vitae exercitationem voluptatem facilis explicabo?</p>
			<Nav>
				<NavItem to={url}>Zugangsdaten</NavItem>
				<NavItem to={url + '/vereine'}>Vereine</NavItem>
				<NavItem to={url + '/bewertungsseiten'}>Bewertungsseiten</NavItem>
				<NavItem to={url + '/dokumente'}>Dokumente</NavItem>
			</Nav>
		</PageHeader>
		<Switch>
			<Route exact path={path}>
				<Protected Cmp={CmpCustomerAdmin} />
			</Route>
			<Route path={path + '/bewertungsseiten'}>
				<Protected Cmp={Bewertungsseiten} />
			</Route>
			<Route path={path + '/vereine'}>
				<Protected Cmp={Vereine} />
			</Route>
			<Route path={path + '/Dokumente'}>
				<Protected Cmp={Dokumente} />
			</Route>
		</Switch>
	</>
}