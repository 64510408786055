import classNames from "classnames"

export function Button({ children, onClick, className, type }) {
  type = type || 'default'

  let styleClass = 'text-hpl bg-hpl_bg hover:bg-gray-300 hover:text-gray-800'

  if (type === 'danger') {
    styleClass = 'text-red-700 bg-red-200 hover:bg-red-100 hover:text-red-300'
  } else if (type === 'secondary') {
    styleClass = 'text-gray-700 bg-gray-200 hover:bg-gray-100 hover:text-gray-400'
  }
  
  return (
    <button
      onClick={onClick}
      className={classNames(['rounded-md py-2 px-6 w-full font-semibold', styleClass, className])}
    >
      {children}
    </button>
  );
}
