import { Container } from "../../ui/Container";
import { Section } from "../../ui/Section";
import { Button } from "../../ui/Button";
import { CardBody } from "../../ui/Card";
import { Modal, ModalHeader } from "../../ui/Modal";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchData, getRole, getUserDetails } from "../../helpers";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "../../ui/Alert";

function Produkte() {
  const [mounted, setMounted] = useState(false);
  const [roleData, setRole] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stateProductTitle, setStateProductTitle] = useState("");
  const [stateProductCategory, setStateProductCategory] = useState("");
  const [stateProductContent] = useState(""); //setStateProductContent
  const [productsData, setProductsData] = useState([]);
  const [errorList, setErrorList] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorState, setErrorState] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    try {
      getRole().then((result) => setRole(result));
      getProducts();
    } catch {
      setErrorState(true);
    }
  }, []);

  async function getProducts() {
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/products", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          setProductsData(data);
          setMounted(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  //post Product function
  function postProduct(event) {
    event.preventDefault();
    let errors = [];
    var content = convertToRaw(editorState.getCurrentContent());
    var productObj = {};
    productObj.title = stateProductTitle;
    productObj.category = stateProductCategory;
    productObj.content = JSON.stringify(content);

    fetchData(process.env.REACT_APP_API_URL + "/products", {
      method: "POST",
      body: JSON.stringify(productObj),
    })
      .then((response) => response.json())
      .then(function (data) {
        if (data.ok) {
          return data;
        }
        if (data.success) {
          setSuccessMessage("Produkt wurde erstellt.");
        }
        errors.push(data.errors);
        setErrorList(data.errors || {});
      })
      .then((response) => getProducts());
  }

  //Validation Handling
  function validationStatus(errorField) {
    if (errorList[errorField])
      return (
        <div className="text-red-900 bg-red-200 rounded-sm -m-0.5 px-4">
          <span className="">{errorList[errorField]}</span>
        </div>
      );
  }
  //success visibility
  let visibility = successMessage == 0 ? "hidden" : "inline";

  //reset form
  const resetInputFields = () => {
    setStateProductCategory("");
    setStateProductTitle("");
    setSuccessMessage("");
  };

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const ProductsTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.products);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <table className="min-w-full divide-y divide-gray-400 px-6 py-3">
        <thead className="">
          <tr>
            <th
              scope="col"
              className="w-5/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("title")}
                className={getClassNamesFor("title")}
              >
                Titel
              </button>
            </th>
            <th
              scope="col"
              className="w-5/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("category")}
                className={getClassNamesFor("category")}
              >
                Kategorie
              </button>
            </th>
            <th></th>
            <th
              scope="col"
              className="w-1/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            ></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {items
            .filter((itemData) => {
              if (searchTerm == "") {
                return itemData;
              } else if (
                itemData.title
                  .toLowerCase()
                  .includes(
                    searchTerm.toLowerCase() ||
                      itemData.category
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
              ) {
                return itemData;
              }
            })
            .map((product, id) => (
              <tr key={"product-" + id}>
                <td className="px-6 py-4 whitespace-nowrap">{product.title}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {product.category}
                </td>
                <td></td>
                <td className="px-6 py-4 whitespace-nowrap float-right w-full">
                  <Link to={"/produkt/" + product.id}>
                    <Button>
                      <FontAwesomeIcon icon={faEdit} className="" />
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {errorState ? (
        <Section>
          <Container>
            <Alert type="secondary">
              Serverfehler! Etwas ist schief gelaufen. Informieren Sie einen
              Entwickler.
            </Alert>
          </Container>
        </Section>
      ) : (
        <div>
          {roleData === "admin" ? (
            <div className="">
              {mounted ? (
                <Section>
                  <Container>
                    <Modal open={isOpen} className="">
                      <ModalHeader
                        heading="Neues Produkt"
                        onClose={() => {
                          setIsOpen(false);
                          resetInputFields();
                        }}
                      />

                      <CardBody className="">
                        <div className="mb-2">
                          {validationStatus("title")}
                          {validationStatus("category")}
                        </div>
                        <form>
                          <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="productTitle"
                              >
                                Titel
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="productTitle"
                                type="text"
                                placeholder="Titel"
                                value={stateProductTitle}
                                onChange={(e) =>
                                  setStateProductTitle(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="productCategory"
                              >
                                Kategorie
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="productCategory"
                                type="text"
                                placeholder="Kategorie"
                                value={stateProductCategory}
                                onChange={(e) =>
                                  setStateProductCategory(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="grid gap-4 mb-4">
                            <div>
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="productContent"
                              >
                                Inhalt
                              </label>
                              <div className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight">
                                <Editor
                                  id="productContent"
                                  value={stateProductContent}
                                  editorState={editorState}
                                  onEditorStateChange={setEditorState}
                                />
                              </div>
                            </div>
                          </div>
                          <p className="text font-semibold rounded mb-1 px-0">
                            {successMessage}{" "}
                            <span className={visibility}>
                              <FontAwesomeIcon
                                icon={faCheckDouble}
                                size="lg"
                                className="text-success"
                              />
                            </span>
                          </p>
                          <Button
                            variant="primary"
                            type=""
                            className=""
                            onClick={postProduct}
                          >
                            Produkt anlegen
                          </Button>
                        </form>
                      </CardBody>
                    </Modal>
                    <div className="flex mb-2">
                      <div className="w-10/12">
                        <h1 className="text-4xl font-bold mb-8">
                          Produkte&nbsp;
                        </h1>
                      </div>
                      <div className="w-2/12">
                        <Button onClick={() => setIsOpen(true)} className="">
                          <FontAwesomeIcon icon={faPlus} className="" />
                        </Button>
                      </div>
                    </div>
                    <div className="w-full mb-8">
                      <Link to="/produkte-zuordnung">
                        <Button className="">Zur Zuordnung der Produkte</Button>
                      </Link>
                    </div>
                    <div className="flex mb-8 flex-row-reverse">
                      <div className="w-2/12 ">
                        <input
                          type="text"
                          className="text-hpl bg-hpl_bg hover:bg-gray-300 hover:text-gray-800 border-none rounded w-full"
                          placeholder="Suche..."
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <ProductsTable products={productsData} />
                    </div>
                  </Container>
                </Section>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          ) : (
            <Section>
              <Container>
                <Alert type="secondary">
                  Auf diese Ansicht haben Sie keinen Zugriff!
                </Alert>
              </Container>
            </Section>
          )}
        </div>
      )}
    </div>
  );
}

export default Produkte;
