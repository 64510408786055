import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import CmpSelliePopup from "../components/CmpSelliePopup";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  let user = JSON.parse(localStorage.getItem("user-details"));
  const history = useHistory();
  function logOut() {
    localStorage.clear();
    window.location.reload();
    history.push("/login");
  }

  return (
    <>
      <div className="flex items-center pt-4 pr-4 pb-4">
        <div className="flex-auto">
          <div className="float-right p-0">
            <button
              className="inline-flex justify-center w-full rounded-md border px-4 py-2 border-none font-semibold"
              onClick={() => setIsOpen(!isOpen)}
            >
              {(user && "Eingeloggt: " + user.name) || ""}
              {/* <div className="inline-block align-middle pl-2">
                <FontAwesomeIcon icon={faChevronDown} className="" />
              </div> */}
            </button>

            {localStorage.getItem("user-details") ? (
              <div>
                {" "}
                {isOpen && (
                  <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
                    <button
                      onClick={logOut}
                      className="group flex items-center px-4 py-2 text-sm text-gray-700 
                      "
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {/* {" "}
                {isOpen ? (
                  <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
                    <a
                      href="/login"
                      className="group flex items-center px-4 py-2"
                    >
                      Login
                    </a>
                    {/* <a
                      disabled
                      href="/register"
                      className="group flex items-center px-4 py-2"
                    >
                      Registrieren
                    </a> */}
                {/*</div>
                ) : (
                  <div></div>
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>

      {localStorage.getItem("user-info") ? (
        user.role === "admin" ? (
          <div> </div>
        ) : (
          <div>
            <CmpSelliePopup />
          </div>
        )
      ) : (
        <div></div>
      )}
    </>
  );
}

export default Header;
