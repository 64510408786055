export function Textarea(props) {
  const { children } = props
  
  return (
    <textarea
      className="appearance-none block w-full text-gray-700 border-none bg-gray-100 rounded py-3 px-4 leading-tight"
      { ...props }
    >{ children }</textarea>
  )
}
