import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import { Button } from "../ui/Button";
import { Link } from "react-router-dom";

function CmpNotFound() {
  return (
    <div className="">
      <Section>
        <Container>
          <h1 className="text-4xl mb-4">404</h1>
          <SectionTitle>Seite konnte nicht gefunden werden!</SectionTitle>
          <div className="mt-4">
            <Link to={"/"}>
              <Button>Zur Startseite</Button>
            </Link>
          </div>
        </Container>
      </Section>
    </div>
  );
}

export default CmpNotFound;
