import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Button } from "../ui/Button";
import { Card, CardBody, CardText } from "../ui/Card";
import "../App.css";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "./CmpSelliePopup.scss";
import sellie from "../assets/Sellie-1.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as fasTimes } from "@fortawesome/free-solid-svg-icons";

import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

function closePopup() {
  document.getElementById("selliePopup").style.display = "none";
  setTimeout(function () {
    document.getElementById("selliePopup").style.display = "initial";
  }, 300000);
}

function CmpSelliePopup() {
  const [offer, setOffer] = useState();
  const [mounted, setMounted] = useState(false);
  const [errorState, setErrorState] = useState(false);
  useEffect(() => {
    getSellieOffers();

    async function getSellieOffers() {
      var oData = [];
      if (localStorage.getItem("user-info")) {
        try {
          await fetchData(process.env.REACT_APP_API_URL + "/useroffers", {
            method: "GET",
          })
            .then((response) => response.json())
            .then(function (data) {
              for (var value of Object.values(data.data)) {
                oData.push(value);
              }
              if (oData.length === 0) {
                var offer = {};
                offer.title = "Hallo, ich bin Sellie!";
                const storedState = convertFromRaw(
                  JSON.parse(
                    '{"blocks":[{"key":"748li","text":"Ich werde Sie über Ihre persönlichen Angebote und den Verlauf Ihrer Projekte informieren.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
                  )
                );
                const contentState = EditorState.createWithContent(storedState);
                offer.content = contentState;
                setOffer(offer);
                setMounted(true);
              } else {
                const id =
                  oData[parseInt(oData.length * Math.random())]["offer_id"];
                getStateOffer(id);
              }
            })
            .catch((error) => {
              console.log("req failed", error);
            });
        } catch (e) {
          console.log(e);
          setErrorState(true);
        }
      }
    }
  }, []);

  async function getStateOffer(id) {
    var res = [];
    if (localStorage.getItem("user-info")) {
      await fetchData(process.env.REACT_APP_API_URL + "/offers/" + id, {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          res = Object.values(data.data)[0];
          var content = res.content;
          const storedState = convertFromRaw(JSON.parse(content));
          const contentState = EditorState.createWithContent(storedState);
          res.content = contentState;
          setOffer(res);
          setMounted(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  return (
    <div id="selliePopup">
      {errorState ? (
        <div></div>
      ) : (
        <div>
          {mounted ? (
            <Card>
              <div className="w-full p-2 justify-center">
                <img
                  id="sellie-img"
                  src={sellie}
                  className="object-cover md:object-top w-full"
                  alt="Sellie"
                />
              </div>
              <CardBody>
                {/* <h2 className="text-xl font-medium text-gray-500">{offer.title}</h2> */}
                <h2 className="text-lg text-center text-gray-700">
                  Herzlich willkommen zur Alphaversion von MyHPL 2.0
                </h2>
                <h3 className="text-center text-sm text-gray-500 mt-1 -mb-5">
                  Falls Ihnen Fehler auffallen oder Sie Verbesserungsvorschläge
                  für uns haben, kontaktieren Sie uns bitte.
                </h3>

                <CardText>
                  {" "}
                  <Editor
                    value={offer.content}
                    editorState={offer.content}
                    readOnly={true}
                  />
                </CardText>
                <div className="">
                  <Button>
                    <a href="mailto:info@homepage-lieferanten.de">
                      E-Mail Kontakt
                    </a>
                  </Button>
                </div>
              </CardBody>
              <div className="absolute top-3 right-3" onClick={closePopup}>
                <FontAwesomeIcon
                  icon={fasTimes}
                  size="2x"
                  className="text-hpl"
                />
              </div>
            </Card>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
}

export default CmpSelliePopup;
