import { DateTime } from "luxon";
import * as _ from "lodash";

export const formatDate = (dateString, format) => {
  const dateTime = DateTime.fromISO(dateString);
  if (format !== undefined) return dateTime.toFormat(format);

  return dateTime.toLocaleString();
};

export const getUserDetails = () => {
  const userDetailsJson = localStorage.getItem("user-details");

  if (userDetailsJson === null) return null;

  const userDetails = JSON.parse(userDetailsJson);

  return userDetails;
};

export const getAccessToken = () => {
  const userInfoJson = localStorage.getItem("user-info");

  if (userInfoJson === null) return null;

  const { token } = JSON.parse(userInfoJson);

  return token;
};

export const fetchData = (url, options = {}) => {
  const token = getAccessToken();

  const defaults = {
    headers: {
      Accept: "application/json",

      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  options = _.merge(defaults, options);

  return fetch(url, options);
};

export const getRole = () => {
  let roleData = fetchData(process.env.REACT_APP_API_URL + "/user", {
    method: "GET",
  })
    .then((response) => response.json())
    .then(function (response) {
      let result = response.user.role;
      return result;
    })
    .catch((error) => {
      console.log("req failed", error);
    });
  return roleData;
};
