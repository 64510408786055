import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardHeader,
  CardAccessory,
  CardFooter,
} from "../ui/Card";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  //faEnvelope,
  faInfoCircle,
  faPhone,
  //faShoppingCart,
  faUsers,
  faChartLine,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";
import { EtrackerLogo } from "../assets/etracker.js";

import "../App.css";
import { MutedText } from "../ui/MutedText";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

export const AddedValueCard = ({
  tippy,
  icon,
  value,
  headline,
  unitText,
  price,
  definition,
}) => {
  return (
    <div className="">
      <Card className="">
        <CardAccessory>
          <Tippy content={<div>{tippy}</div>}>
            <div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="lg"
                className="text-gray-400"
              />
            </div>
          </Tippy>
        </CardAccessory>
        <CardHeader>
          <CardTitle>
            {typeof icon !== "object" ? (
              <div
                style={{
                  height: "1.7rem",
                  width: "1.7rem",
                }}
                className="inline"
              >
                <EtrackerLogo />
              </div>
            ) : (
              <FontAwesomeIcon icon={icon} className="inline" />
            )}
            &nbsp;
            {value.toLocaleString()}{" "}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <strong>{headline}</strong>
          <CardText>
            {unitText} &nbsp; <b>{price.toLocaleString()}&euro;</b>
          </CardText>
          <div className="text-2xl font-semibold mt-5 mb-2">
            <MutedText>
              <FontAwesomeIcon icon={faArrowRight} className="" />{" "}
              {(value * price).toLocaleString()}
              &euro;
            </MutedText>
          </div>
        </CardBody>
        <hr />
        <CardFooter>
          <strong>Definition:</strong>
          <div>{definition}</div>
        </CardFooter>
      </Card>
    </div>
  );
};
