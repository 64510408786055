import React, { useState, useLayoutEffect } from "react";
import { fetchData } from "../helpers";
import { Section, SectionTitle } from "../ui/Section";
import { Card, CardBody, CardFooter } from "../ui/Card";
import { LeadText } from "../ui/LeadText";

function CmpKeywords({ CurrentProject }) {
  const [mounted, setMounted] = useState(false);
  const [kArray, setKArray] = useState([]);
  const [timestamp, setTimestamp] = useState([]);

  useLayoutEffect(() => {
    function getKeywords() {
      var data = [];
      if (localStorage.getItem("user-info")) {
        fetchData(
          process.env.REACT_APP_API_URL +
            "/projects/" +
            CurrentProject.id +
            "/defaultKeywords",
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then(function (response) {
            data = response.data;
            if (data.response) {
              setKArray(JSON.parse(data.response)["answer"][0].result);
            } else setKArray(JSON.parse(data.original.data.response)["answer"][0].result);

            var date = "";

            if (data.created_at !== undefined) {
              date = new Date(data.created_at);
            } else {
              date = new Date();
            }

            setTimestamp(
              date.getDate() +
                "." +
                (date.getMonth() + 1) +
                "." +
                date.getFullYear()
            );

            setMounted(true);
          })
          .catch((error) => {
            console.log("req failed", error);
          });
      }
    }
    getKeywords();
  }, [CurrentProject]);

  const kwTable = kArray.map((keyword, id) => {
    return (
      <tr key={"defaultKeywords-row-" + id}>
        <td className="px-3 py-2">{id + 1}</td>
        <td className="px-3 py-2">{kArray[id]["position"]}</td>
        <td className="px-3 py-2">{kArray[id]["kw"]}</td>
      </tr>
    );
  });

  return (
    <div className="">
      {mounted ? (
        <Section>
          <SectionTitle>Allgemeine Top Keywords</SectionTitle>
          <LeadText>
            Die aufgelisteten Keywords wurden automatisch erzeugt und
            analysiert. Diese können von den von uns ausgewählten Keywords
            abweichen.
          </LeadText>
          <Card className="">
            <CardBody>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Keyword
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {kwTable}
                </tbody>
              </table>
            </CardBody>
            <CardFooter>
              <p className="italic">Stand: {timestamp}</p>
            </CardFooter>
          </Card>
        </Section>
      ) : (
        <div>
          <Section>Loading...</Section>
        </div>
      )}
    </div>
  );
}
export default CmpKeywords;
