import { Link } from "react-router-dom";
import { Container } from "../../ui/Container";
import { Button } from "../../ui/Button";
import { Modal, ModalHeader } from "../../ui/Modal";
import { Section } from "../../ui/Section";
import { CardBody } from "../../ui/Card";
import React, { useState, useEffect } from "react";
import { fetchData, getRole, getUserDetails } from "../../helpers";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "../../ui/Alert";

function Projekte() {
  const [mountedC, setMountedC] = useState(false);
  const [mountedP, setMountedP] = useState(false);
  const [roleData, setRole] = useState([]);
  const [customersData, setCustomers] = useState([]);
  const [projectsData, setProjects] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stateProjectUserID, setStateProjectUserID] = useState("1");
  const [stateProjectName, setStateProjectName] = useState("");
  const [stateProjectViewID, setStateProjectViewID] = useState("");
  const [stateProjectSiteURL, setStateProjectSiteURL] = useState("");
  const [stateProjectDatePubl, setStateProjectDatePubl] = useState("");
  const [errorList, setErrorList] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorState, setErrorState] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    try {
      getRole().then((result) => setRole(result));
      getCustomers();
      getProjects();
    } catch {
      setErrorState(true);
    }
  }, []);

  async function getCustomers() {
    var cuData = [];
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/user/list", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          for (var ii = 0; ii < data.data.length; ii++) {
            cuData.push(data.data[ii]);
          }
          setCustomers(cuData);
          setMountedC(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  async function getProjects() {
    var prData = [];
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/projects", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          for (var ii = 0; ii < data.data.length; ii++) {
            prData.push(data.data[ii]);
          }
          setProjects(prData);
          setMountedP(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  //load customers in Form Dropdown
  const customersDropdown = customersData.map((customer, id) => {
    return {
      label: customer.name,
      value: customer.id,
    };
  });

  //handle dropdown change
  function handleSelectChange(event) {
    setStateProjectUserID(event.value);
  }

  //post Project function
  function postProject(event) {
    event.preventDefault();
    let errors = [];
    var projectObj = {};
    projectObj.user_id = stateProjectUserID;
    projectObj.name = stateProjectName;
    projectObj.google_analytics_view_id = stateProjectViewID;
    projectObj.site_url = stateProjectSiteURL;
    projectObj.publication_date = stateProjectDatePubl;
    projectObj.latest_routine_check = stateProjectDatePubl;
    projectObj.latest_data_privacy_check = stateProjectDatePubl;
    projectObj.next_routine_check =
      parseInt(stateProjectDatePubl.split("-")[0]) +
      1 +
      "-" +
      stateProjectDatePubl.split("-")[1] +
      "-" +
      stateProjectDatePubl.split("-")[2];
    projectObj.tiles =
      '{"seo": false, "devices": false, "tickets": false, "website": false, "visitors": false, "google_ads": false, "newsletter": false, "added_value": false, "automations": false, "added_value_shop": false, "default_keywords": false, "digital_strategy": false, "added_value_unique_visitors": false, "added_value_leads": false, "google_references": false, "added_value_emails": false, "next_routine_check": false, "added_value_sales_calls": false}';
    projectObj.custom_prices =
      '{"calls": 1, "emails": 1, "shop": 1, "leads": 1, "sales_calls": 1}';
    //latest routine check and privacy check same as publ date!

    /**
     * post Project
     */

    fetchData(process.env.REACT_APP_API_URL + "/projects", {
      method: "POST",
      body: JSON.stringify(projectObj),
    })
      .then((response) => response.json())
      .then(function (data) {
        if (data.ok) {
          return data;
        }
        if (data.success) {
          setSuccessMessage("Projekt wurde erstellt.");
        }
        errors.push(data.errors);
        setErrorList(data.errors || {});
      })
      .then((response) => getProjects());
  }

  //Validation Handling
  function validationStatus(errorField) {
    if (errorList[errorField])
      return (
        <div className="text-red-900 bg-red-200 rounded-sm -m-0.5 px-4">
          <span className="">{errorList[errorField]}</span>
        </div>
      );
  }
  //success visibility
  let visibility = successMessage == 0 ? "hidden" : "inline";

  //reset form
  const resetInputFields = () => {
    setStateProjectName("");
    setStateProjectViewID("");
    setStateProjectSiteURL("");
    setStateProjectDatePubl("");
    setStateProjectUserID("");
    setSuccessMessage("");
  };

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const ProjectsTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.projects);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <table className="min-w-full divide-y divide-gray-400 px-6 py-3">
        <thead className="">
          <tr>
            <th
              scope="col"
              className="w-5/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Name
              </button>
            </th>
            <th
              scope="col"
              className="w-5/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("user_id")}
                className={getClassNamesFor("user_id")}
              >
                Nutzer
              </button>
            </th>
            <th></th>
            <th
              scope="col"
              className="w-1/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            ></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {items
            .filter((itemData) => {
              if (searchTerm == "") {
                return itemData;
              } else if (
                itemData.name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                customersData
                  .filter((u) => u.id === itemData.user_id)
                  .map((u) => u.name)
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return itemData;
              }
            })
            .map((project, id) => (
              <tr key={"project-" + id}>
                <td className="px-6 py-4 whitespace-nowrap">{project.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {customersData
                    .filter((u) => u.id === project.user_id)
                    .map((u) => u.name)}
                </td>
                <td></td>
                <td className="px-6 py-4 whitespace-nowrap float-right w-full">
                  <Link to={"/projekt/" + project.id}>
                    <Button>
                      <FontAwesomeIcon icon={faEdit} className="" />
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {errorState ? (
        <Section>
          <Container>
            <Alert type="secondary">
              Serverfehler! Etwas ist schief gelaufen. Informieren Sie einen
              Entwickler.
            </Alert>
          </Container>
        </Section>
      ) : (
        <div>
          {roleData === "admin" ? (
            <div className="">
              {mountedC && mountedP ? (
                <Section>
                  <Container>
                    <Modal open={isOpen} className="">
                      <ModalHeader
                        heading="Neues Projekt"
                        onClose={() => {
                          setIsOpen(false);
                          resetInputFields();
                        }}
                      />

                      <CardBody className="">
                        <div className="mb-2">
                          {validationStatus("name")}
                          {validationStatus("site_url")}
                          {validationStatus("google_analytics_view_id")}
                          {validationStatus("publication_date")}
                        </div>
                        <form>
                          <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-state"
                              >
                                Kunde / Benutzer
                              </label>
                              <Select
                                onMenuOpen={onMenuOpen}
                                onMenuClose={onMenuClose}
                                options={customersDropdown}
                                onChange={handleSelectChange}
                                placeholder="Kunde..."
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="projectName"
                              >
                                Name
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="projectName"
                                type="text"
                                placeholder="Name"
                                value={stateProjectName}
                                onChange={(e) =>
                                  setStateProjectName(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="siteUrl"
                              >
                                Seiten-URL
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="siteUrl"
                                type="text"
                                placeholder="Seiten-Url"
                                value={stateProjectSiteURL}
                                onChange={(e) =>
                                  setStateProjectSiteURL(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="gaViewId"
                              >
                                Google Analytics View ID
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="gaViewId"
                                type="text"
                                placeholder="View ID"
                                value={stateProjectViewID}
                                onChange={(e) =>
                                  setStateProjectViewID(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="publicationDate"
                              >
                                Veröffentlichungsdatum
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="publicationDate"
                                type="date"
                                value={stateProjectDatePubl}
                                onChange={(e) =>
                                  setStateProjectDatePubl(e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <p className="text font-semibold rounded mb-1 px-0">
                            {successMessage}{" "}
                            <span className={visibility}>
                              <FontAwesomeIcon
                                icon={faCheckDouble}
                                size="lg"
                                className="text-success"
                              />
                            </span>
                          </p>
                          <Button
                            variant="primary"
                            type=""
                            className=""
                            onClick={postProject}
                          >
                            Projekt anlegen
                          </Button>
                        </form>
                      </CardBody>
                    </Modal>
                    <div className="flex mb-2">
                      {" "}
                      <div className="w-10/12">
                        <h1 className="text-4xl font-bold mb-8">
                          Projekte&nbsp;
                        </h1>
                      </div>
                      <div className="w-2/12">
                        <Button
                          onClick={() => {
                            setIsOpen(true);
                            getProjects();
                          }}
                          className=""
                        >
                          <FontAwesomeIcon icon={faPlus} className="" />
                        </Button>
                      </div>
                    </div>
                    <div className="flex mb-8 flex-row-reverse">
                      <div className="w-2/12 ">
                        <input
                          type="text"
                          className="text-hpl bg-hpl_bg hover:bg-gray-300 hover:text-gray-800 border-none rounded w-full"
                          placeholder="Suche..."
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <ProjectsTable projects={projectsData} />
                    </div>
                  </Container>
                </Section>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          ) : (
            <Section>
              <Container>
                <Alert type="secondary">
                  Auf diese Ansicht haben Sie keinen Zugriff!
                </Alert>
              </Container>
            </Section>
          )}
        </div>
      )}
    </div>
  );
}

export default Projekte;
