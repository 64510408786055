import React, { useState, useEffect } from "react";
import { Container } from "../ui/Container";
import { SectionTitle } from "../ui/Section";
import { AddedValueCard } from "../ui/AddedValueCard";
import CmpAddedValueGraphGoogleAnalytics from "./CmpAddedValueGraphGoogleAnalytics";
import {
  faPhone,
  faUsers,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { faWpforms } from "@fortawesome/free-brands-svg-icons";

import "../App.css";
import "tippy.js/dist/tippy.css";

function CmpAddedValueGoogleAnalytics({ CurrentProject, startDate, endDate }) {
  const [mounted, setMounted] = useState(false);
  var currentDate = new Date();

  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const [addedValueDataTotal, setAddedValueDataTotal] = useState({
    UNIQUE_VISITORS: 0,
    //SHOP: 0,
    LEADS: 0,
    SALES_CALLS: 0,
    //EMAILS: 0,
    CUSTOM: {},
    TOTAL: 0,
  });

  const [addedValuesDataPeriod, setAddedValuesDataPeriod] = useState({
    UNIQUE_VISITORS: 0,
    //SHOP: 0,
    LEADS: 0,
    SALES_CALLS: 0,
    //EMAILS: 0,
    CUSTOM: {},
  });

  const [customPrices] = useState(CurrentProject.custom_prices);

  const [resultAddedValue, setResultAddedValue] = useState({});

  useEffect(() => {
    setMounted(false);
    try {
      getAddedValuesPeriod();
      getAddedValuesTotal();
      getResultAddedValue();
    } catch {}

    setMounted(true);
  }, [startDate, endDate]);

  var projecttiles = CurrentProject.tiles;

  function getCustomsCustomAddedValues(av) {
    if (Object.keys(av).length === 0) {
      return 0;
    }
    var result = 0;
    var entries = Object.entries(av);
    for (var cAddedV of entries) {
      result =
        result +
        cAddedV[1] * customPrices["CUSTOM-" + cAddedV[0].split("-")[0]];
    }
    return result;
  }

  async function getCustomAddedValues(startDate, endDate) {
    var result = {
      UNIQUE_VISITORS: 0,
      //SHOP: 0,
      LEADS: 0,
      SALES_CALLS: 0,
      //EMAILS: 0,
    };
    if (localStorage.getItem("user-info")) {
      var res = await fetch(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/trackings?start=" +
          startDate +
          "&end=" +
          endDate,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization:
              "Bearer " +
              JSON.parse(localStorage.getItem("user-info"))["token"],
            "Access-Control-Allow-Origin": "*",
          },
        }
      ).then((response) => response.json());
      if (typeof res !== "undefined") {
        result.UNIQUE_VISITORS = res.data.UNIQUE_VISITORS;
        //result.SHOP = res.data.SHOP;
        result.LEADS = res.data.LEADS;
        result.SALES_CALLS = res.data.SALES_CALLS;
        //result.EMAILS = res.data.EMAILS;
        result.CUSTOM = res.data.CUSTOM;
      }
      return result;
    }
  }

  function getAddedValuesTotal() {
    var totalObj = {
      UNIQUE_VISITORS: 0,
      //SHOP: 0,
      LEADS: 0,
      SALES_CALLS: 0,
      //EMAILS: 0,
      CUSTOM: {},
      TOTAL: 0,
    };

    getCustomAddedValues(
      CurrentProject.publication_date,
      currentDate.toISOString().substr(0, 10)
    )
      .then((res) => {
        totalObj.UNIQUE_VISITORS = res.UNIQUE_VISITORS;
        //totalObj.SHOP = res.SHOP;
        totalObj.LEADS = res.LEADS;
        totalObj.SALES_CALLS = res.SALES_CALLS;
        //totalObj.EMAILS = res.EMAILS;
        totalObj.CUSTOM = res.CUSTOM;
      })
      .then(() => {
        totalObj.TOTAL =
          totalObj.UNIQUE_VISITORS * customPrices.UNIQUE_VISITORS +
          //totalObj.SHOP * customPrices.SHOP +
          totalObj.LEADS * customPrices.LEADS +
          totalObj.SALES_CALLS * customPrices.SALES_CALLS +
          //totalObj.EMAILS * customPrices.EMAILS;
          getCustomsCustomAddedValues(totalObj.CUSTOM);
        setAddedValueDataTotal(totalObj);
      });
  }

  function getAddedValuesPeriod() {
    getCustomAddedValues(startDate, endDate).then((value) => {
      var data = addedValuesDataPeriod;
      data.UNIQUE_VISITORS = value.UNIQUE_VISITORS;
      //data.SHOP = value.SHOP;
      data.LEADS = value.LEADS;
      data.SALES_CALLS = value.SALES_CALLS;
      //data.EMAILS = value.EMAILS;
      data.CUSTOM = value.CUSTOM;
      setAddedValuesDataPeriod(data);
      getResultAddedValue();
    });
  }

  function getResultAddedValue() {
    var resultObj = {
      UNIQUE_VISITORS: (
        Math.round(
          addedValuesDataPeriod.UNIQUE_VISITORS *
            customPrices.UNIQUE_VISITORS *
            100
        ) / 100
      ).toFixed(2),
      // SHOP: (
      //   Math.round(addedValuesDataPeriod.SHOP * customPrices.SHOP * 100) / 100
      // ).toFixed(2),
      LEADS: (
        Math.round(addedValuesDataPeriod.LEADS * customPrices.LEADS * 100) / 100
      ).toFixed(2),
      SALES_CALLS: (
        Math.round(
          addedValuesDataPeriod.SALES_CALLS * customPrices.SALES_CALLS * 100
        ) / 100
      ).toFixed(2),
      // EMAILS: (
      //   Math.round(addedValuesDataPeriod.EMAILS * customPrices.EMAILS * 100) /
      //   100
      // ).toFixed(2),
      CUSTOM: (
        Math.round(
          getCustomsCustomAddedValues(addedValuesDataPeriod.CUSTOM) * 100
        ) / 100
      ).toFixed(2),
    };
    setResultAddedValue(resultObj);
  }
  const customAddedValueTiles = Object.entries(
    addedValuesDataPeriod.CUSTOM
  ).map((customData, id) => {
    var key = customData[0].split("-");
    return (
      <div className="" key={"custom-tracking-tile-" + customData[0]}>
        <AddedValueCard
          tippy="Das individuelle Tracking gibt Ihnen einen Einblick über
                  laufende Kampagnen."
          icon={faChartLine}
          value={customData[1]}
          headline={key[1]}
          unitText="Festgelegter Preis pro Tracking:"
          price={customPrices["CUSTOM-" + key[0]]}
          definition={customPrices["DESCR:CUSTOM-" + key[0]]}
        />
      </div>
    );
  });

  return (
    <div className="mb-8">
      {mounted ? (
        <div>
          <SectionTitle>
            ... haben wir diesen Mehrwert für Sie realisiert und über Google
            Analytics ausgewertet
          </SectionTitle>
          <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 mb-8">
            {projecttiles.added_value_unique_visitors ? (
              <AddedValueCard
                tippy="Unique Visits sind User auf Ihrer Website, welche
                              nur einmal gezählt werden, egal wie oft Sie die
                              Seite besuchen."
                icon={faUsers}
                value={addedValuesDataPeriod.UNIQUE_VISITORS}
                headline="Unique Visitors"
                unitText="Festgelegter Preis pro Besucher:"
                price={customPrices.UNIQUE_VISITORS}
                definition={customPrices["DESCR:UNIQUE_VISITORS"]}
              />
            ) : (
              <></>
            )}
            {projecttiles.added_value_leads ? (
              <AddedValueCard
                tippy="Qualifizierte Leads, die über den
                              Anfragekonfigurator der Webseite eingegangen sind."
                icon={faWpforms}
                value={addedValuesDataPeriod.LEADS}
                headline="Qualifizierte Leads"
                unitText="Festgelegter Preis pro Lead:"
                price={customPrices.LEADS}
                definition={customPrices["DESCR:LEADS"]}
              />
            ) : (
              <></>
            )}
            {projecttiles.added_value_sales_calls ? (
              <AddedValueCard
                tippy="Vertriebsanrufe, die über die Webseite eingegangen
                              sind."
                icon={faPhone}
                value={addedValuesDataPeriod.SALES_CALLS}
                headline="Vertriebsanrufe"
                unitText="Festgelegter Preis pro Anruf:"
                price={customPrices.SALES_CALLS}
                definition={customPrices["DESCR:SALES_CALLS"]}
              />
            ) : (
              <></>
            )}

            {customAddedValueTiles}
          </div>
          <SectionTitle>
            Ihre Webseite hat im Zeitraum vom&nbsp;
            {startDate.substr(8, 2) +
              ". " +
              monthNames[parseInt(startDate.substr(5, 2) - 1)] +
              " " +
              startDate.substr(0, 4)}
            &nbsp;bis&nbsp;
            {endDate.substr(8, 2) +
              ". " +
              monthNames[parseInt(endDate.substr(5, 2)) - 1] +
              " " +
              endDate.substr(0, 4)}
            &nbsp;
            <span className="text-hpl underline">
              {(
                Math.round(
                  (parseFloat(resultAddedValue.UNIQUE_VISITORS) +
                    //parseFloat(resultAddedValue.SHOP) +
                    parseFloat(resultAddedValue.LEADS) +
                    parseFloat(resultAddedValue.SALES_CALLS) +
                    //parseFloat(resultAddedValue.EMAILS)) *
                    parseFloat(resultAddedValue.CUSTOM)) *
                    100
                ) / 100
              ).toFixed(2)}
              &euro;
            </span>{" "}
            erwirtschaftet. Seit Start des Projekts haben Sie{" "}
            <span className="text-hpl underline">
              {(Math.round(addedValueDataTotal.TOTAL * 100) / 100).toFixed(2)}
              &euro;
            </span>{" "}
            erwirtschaftet.
          </SectionTitle>

          <CmpAddedValueGraphGoogleAnalytics CurrentProject={CurrentProject} />
        </div>
      ) : (
        <div>
          <Container>Loading...</Container>
        </div>
      )}
    </div>
  );
}
export default CmpAddedValueGoogleAnalytics;
