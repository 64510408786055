import React, { useState } from "react";
import { fetchData } from "../helpers";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import { Card, CardBody } from "../ui/Card";
import { Button } from "../ui/Button";
import { LeadText } from "../ui/LeadText";

function CmpTickets({ CurrentProject }) {
  const [ticket] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("user-details"));

  const [stateEmail, setStateEmail] = useState(currentUser.email);
  const [stateProject, setStateProject] = useState(CurrentProject.name);
  const [stateRegarding, setStateRegarding] = useState(ticket.regarding);
  const [stateMessage, setStateMessage] = useState(ticket.message);

  const [ticketWarning, setTicketWarning] = useState("");

  function onEmailChange(event) {
    setStateEmail(event.target.value);
  }

  function onProjectChange(event) {
    setStateProject(event.target.value);
  }

  function onRegardingChange(event) {
    setStateRegarding(event.target.value);
  }

  function onMessageChange(event) {
    setStateMessage(event.target.value);
  }

  function onSend(event) {
    event.preventDefault();
    var ticketObj = {
      user_id: currentUser.id,
      email: stateEmail,
      name: currentUser.name,
      project: stateProject,
      regarding: stateRegarding,
      message: stateMessage,
    };

    if (
      ticketObj.email &&
      ticketObj.project &&
      ticketObj.regarding &&
      ticketObj.message
    ) {
      fetchData(process.env.REACT_APP_API_URL + "/sendticket", {
        method: "POST",
        body: JSON.stringify(ticketObj),
      })
        .then((response) => response.json())
        .then(function (data) {
          if (!data.success) {
            alert(
              "Ticket konnte nicht erfolgreich versendet werden. Bitte senden Sie Ihr Anliegen über Ihren Mail-Client an hilfe@homepage-lieferanten.de."
            );
          } else {
            setTicketWarning("Ticket wurde versendet!");
            document.getElementById("contact-form").reset();
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      window.alert(
        "Füllen Sie bitte zum Absenden des Tickets alle Felder aus."
      );
    }
  }

  return (
    <div className="" id="tickets">
      <Section>
        <Container>
          <SectionTitle>
            <div className="-mb-7">Ihre Tickets</div>
          </SectionTitle>
          <LeadText>
            <div className="mb-10">
              Über das Formular können Sie Tickets und Änderungswünsche direkt
              an uns weiterleiten.
            </div>
          </LeadText>
          <Card className="">
            <CardBody className="">
              <form id="contact-form">
                <div className="grid gap-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="">
                      <label htmlFor="email">Ihre E-Mail Adresse</label>
                      <input
                        type="email"
                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                        value={ticket.email}
                        defaultValue={currentUser.email}
                        placeholder={currentUser.email}
                        onChange={onEmailChange.bind(this)}
                      />
                    </div>
                    <div className="">
                      <label htmlFor="project">Projekt/Webseite</label>
                      <input
                        type="project"
                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                        value={ticket.project}
                        defaultValue={CurrentProject.name}
                        placeholder={CurrentProject.name}
                        onChange={onProjectChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="regarding">Betreff</label>
                    <input
                      type="text"
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      value={ticket.regarding}
                      placeholder="Betreff"
                      onChange={onRegardingChange.bind(this)}
                    />
                  </div>
                  <div>
                    <label htmlFor="message">Nachricht</label>
                    <textarea
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      rows="5"
                      value={ticket.message}
                      placeholder="Nachricht"
                      onChange={onMessageChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="mt-4 w-full">
                  <Button
                    variant="primary"
                    type=""
                    className=""
                    onClick={onSend}
                  >
                    Absenden
                  </Button>
                </div>
              </form>
              <div>
                <p className="text-hpl">{ticketWarning}</p>
              </div>
            </CardBody>
          </Card>
        </Container>
      </Section>
    </div>
  );
}
export default CmpTickets;
