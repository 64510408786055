import classNames from "classnames"
import { useEffect, useState } from "react"

function Dropdown({ items, onChange, placeholder, type }) {

  const [activeItemIndex, setActiveItemIndex] = useState(0)

  useEffect(() => {
        if (items.length > 0 && !placeholder) {
          onChange(items[0])
        }
  }, [items])

  let styleClass = 'text-hpl bg-hpl_bg hover:bg-gray-300 hover:text-gray-800'

  if (type === 'danger') {
    styleClass = 'text-red-700 bg-red-200 hover:bg-red-100 hover:text-red-300'
  } else if (type === 'secondary') {
    styleClass = 'text-gray-700 bg-gray-200 hover:bg-gray-100 hover:text-gray-400'
  }

  const handleChange = (event) => {
    setActiveItemIndex(event.target.value)
    onChange(items[event.target.value])
  }

  if (items.length === 0) {
    return <><p>Keine Einträge verfügbar</p></>
  }
  
  return (
      <select
        className={classNames(['rounded-md p-2.5 w-full font-bold border-none text-center', styleClass])}
        name="item"
        defaultValue={0}
        selected={activeItemIndex}
        onChange={handleChange}
      >
        { placeholder ? (
          <option key="placeholder" value={0}>
            { placeholder }
          </option>
        ) : null }
        { items.map((item, index) => {
          return (
            <option key={'item-' + item.id} value={index}>
              {item.name}
            </option>
          )
        }) }
      </select>
    )
}

export default Dropdown;
