import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails, getRole } from "../../helpers";
import { Section } from "../../ui/Section";
import { Container } from "../../ui/Container";
import { Button } from "../../ui/Button";
import { Modal, ModalHeader } from "../../ui/Modal";
import { CardBody } from "../../ui/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
//import { PageHeader, PageTitle } from "../../ui/Page"
//import { Nav, NavItem } from "../../ui/Nav"

function CmpCustomerAdmin() {
  const history = useHistory();
  var id = window.location.pathname.split("/")[2];
  const [mounted, setMounted] = useState(false);
  const [roleData, setRole] = useState([]);
  const [user, setUser] = useState([]);
  const [stateUserName, setStateUserName] = useState(user.name);
  const [stateUserEmail, setStateUserEmail] = useState(user.email);
  const [stateUserRole, setStateUserRole] = useState(user.role);
  const [stateUserPassword, setStateUserPassword] = useState(user.password);
  const [successMessage, setSuccessMessage] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getRole().then((result) => setRole(result));
    getUser();

    async function getUser() {
      if (getUserDetails()) {
        fetchData(process.env.REACT_APP_API_URL + "/user/" + id, {
          method: "GET",
        })
          .then((data) => data.json())
          .then(function (data) {
            setUser(data.data);
            setMounted(true);
          })
          .catch((error) => {
            console.log("req failed", error);
          });
      }
    }
  }, [id]);

  //update user function
  function onSave(event) {
    event.preventDefault();
    var userObj = {};
    userObj.name = stateUserName;
    userObj.email = stateUserEmail;
    userObj.role = stateUserRole;
    userObj.password = stateUserPassword;

    fetchData(process.env.REACT_APP_API_URL + "/user/" + user.id, {
      method: "PUT",
      body: JSON.stringify(userObj),
    })
      .then((response) => response.json())
      .then(function (status) {
        if (status.success) {
          setSuccessMessage("Änderungen wurden gespeichert. ");
        }
      })
      .then((response) => event.preventDefault())
      .catch((errors) => {
        alert("Bitte das Passwort Feld ausfüllen!");
      });
  }

  function onDelete() {
    fetchData(process.env.REACT_APP_API_URL + "/user/" + user.id, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((response) => "Deleted!" + response);

    history.push("/kunden");
  }

  //handle dropdown change
  function handleSelectChange(event) {
    setStateUserRole(event.target.value);
  }

  return (
    <div className="">
      {roleData === "admin" ? (
        mounted ? (
          <Section>
            <Container>
              <Modal open={isOpen} className="p-3">
                <ModalHeader
                  heading="Diesen Nutzer löschen? &nbsp;"
                  onClose={() => setIsOpen(false)}
                />

                <CardBody className="">
                  <Button
                    variant="primary"
                    className="rounded-md p-2 bg-hpl text-white w-full"
                    onClick={onDelete}
                  >
                    Nutzer löschen
                  </Button>
                </CardBody>
              </Modal>
              <div className="flex mb-8">
                <h1 className="text-4xl font-bold w-10/12">{user.name}</h1>{" "}
                <div className="flex-auto w-2/12 p-0 font-bold">
                  <div className="float-right w-full">
                    <Button
                      variant="primary"
                      type=""
                      className="justify-center "
                      onClick={() => setIsOpen(true)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="" />
                    </Button>
                  </div>
                </div>
              </div>
              <form>
                <div className="grid grid-cols-2 gap-4">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      E-Mail
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="email"
                      type="email"
                      placeholder={user.email}
                      defaultValue={user.email}
                      onChange={(e) => setStateUserEmail(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="password"
                    >
                      Passwort *
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="password"
                      type="text"
                      placeholder={user.password}
                      defaultValue={user.password}
                      onChange={(e) => setStateUserPassword(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      id="name"
                      type="text"
                      placeholder={user.name}
                      defaultValue={user.name}
                      onChange={(e) => setStateUserName(e.target.value)}
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="role"
                    >
                      Rolle
                    </label>
                    <select
                      className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                      name="role"
                      selected={user.role}
                      onChange={handleSelectChange}
                    >
                      {user.role === "user" ? (
                        <>
                          <option>user</option>
                          <option>admin</option>
                        </>
                      ) : (
                        <>
                          <option>admin</option>
                          <option>user</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="mt-4 mb-4">
                  <hr />
                  <p className="text-sm">
                    <strong>*</strong>Um die Änderungen zu speichern,{" "}
                    <strong>muss</strong> das Passwort eingegeben werden oder
                    ein <strong>neues</strong> gesetzt werden.
                  </p>
                </div>
                <div className="w-1/3 content-center mx-auto">
                  <p className="bg-success rounded text-black text-center text-lg mb-4">
                    {successMessage}
                  </p>
                </div>
                <Button variant="primary" type="" className="" onClick={onSave}>
                  Speichern
                </Button>
              </form>
            </Container>
          </Section>
        ) : (
          <div>
            <Container>Loading...</Container>
          </div>
        )
      ) : (
        <>
          <h1>Auf diese Ansicht haben Sie keinen Zugriff!</h1>
        </>
      )}
    </div>
  );
}

export default CmpCustomerAdmin;
