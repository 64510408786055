import { config } from "@fortawesome/fontawesome-svg-core"
import { faPencilAlt, faPlus, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import RatingpagesDropdown from "../../components/RatingpagesDropdown"
import { Button } from "../../ui/Button"
import { Container } from "../../ui/Container"
import { Label } from "../../ui/Label"
import { List, ListItem } from "../../ui/List"
import { Section, SectionTitle } from "../../ui/Section"
import { Textarea } from "../../ui/Textarea"
import { TextInput } from "../../ui/TextInput"

function Dokumente() {

  const { id } = useParams()
  
  const [url, setUrl] = useState('https://')
  const [thumbnail, setThumbnail] = useState(null)
  const [ratingpage, setRatingpage] = useState(null)
  const [file, setFile] = useState(null)
  const fileReader = new FileReader();

  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  const isUrlValid = regexp.test(url)
  
  fileReader.onload = event => {
      setThumbnail(event.target.result)
  }

  useEffect(() => {
    if (file === null) {
      setThumbnail(null)
    } else {
      fileReader.readAsDataURL(file);
    }
  }, [file, fileReader])
  
  const handleRatingpageChange = (ratingpage) => {
    setRatingpage(ratingpage)
  }
  
  const handleInputChange = (event) => {
    setFile(event.target.files[0])
  }
  
  const documents = [
    { id: 'review_flyer', visible: isUrlValid, name: 'Flyer', description: '4/4 Farbig wählen', product: 'https://www.flyeralarm.com/de/p/flyer-klassiker-4191540.html#/d/att.ausfuehrung.conf=value.dinformat.conf/att.format.conf=value.dinA6105X148Cm.conf/att.material.conf=value.135GBilderdruckMattPefc.conf/att.veredelung.conf=value.keineVeredelung.conf&/l/5756' },
    { id: 'review_business_card', visible: isUrlValid, name: 'Visitenkarte', description: '"1 Motiv" wählen', product: 'https://www.flyeralarm.com/de/p/visitenkarten-klassiker-4321237.html#/d/att.format.conf=value.querformat85X55Cm.conf/att.material.conf=value.300GBilderdruckMatt.conf/att.veredelung.conf=value.beidseitigerDispersionslackMatt.conf/att.farbigkeit.conf=value.44farbig.conf&/l/7050' },
    { id: 'review_sticker', visible: isUrlValid, name: 'Aufkleber', description: '4/0 Farbig wählen', product: 'https://www.flyeralarm.com/de/p/outdoor-aufkleber-4245640.html#/d/att.ausfuehrung.conf=value.rundoval.conf/att.format.conf=value.rundDurchmesser14Cm.conf/att.material.conf=value.90MHaftfolieWeissMatt.conf&/l/34' },
    { id: 'review_signature', visible: true, name: 'E-Mail Signatur', description: '', product: '' },
    { id: 'review_qr_code', visible: isUrlValid, name: 'QR-Code', description: '', product: '' },
  ]
  
  
  
  
  
  return (
    <>
      <Section>
        <Container>
          <form method="post" action={process.env.REACT_APP_API_URL + '/documents'} encType="multipart/form-data" target="_blank">
            <List>
              <ListItem>
                  <SectionTitle>Bewertungstool</SectionTitle>
                  <p className="mb-3">
                    Gib nachfolgend die URL an, die zu der Bewertungsseite auf der Website des Kunden führt.
                  </p>
                  <p className="mb-3">
                    <Label>URL zur Bewertungsseite</Label>
                    <TextInput defaultValue={url} name="url" onChange={event => setUrl(event.target.value)} />
                  </p>
                  <p className="mb-3">
                    <Label>Bewertungsseite</Label>
                    <RatingpagesDropdown onChange={handleRatingpageChange} userId={id} />
                  </p>

                  <Label>Logo</Label>
                  { thumbnail ? (
                    <>
                      <img src={thumbnail} alt="" className="w-32 h-auto" />
                      <a href="#" onClick={() => setFile(null)}>Entfernen</a>
                    </>
                  ) :  null }
                    <input type="file" id="logo" className={classNames([thumbnail ? 'hidden' : ''])} name="logo" accept="image/*" onChange={handleInputChange} />
              </ListItem>
              { documents.map(document => {
                if (!document.visible) return;
                
                return (
                  <ListItem key={'document-' + document.id}>
                    <div className="flex items-center">
                      <div className="flex-1">
                        <div>
                          <strong>{ document.name }</strong><br />
                          <p>{ document.description }</p>
                          { document.product ? (
                            <a href={document.product} target="_blank" rel="noreferrer" className="text-primary">Druckprodukt aufrufen</a>
                          ) : null}
                        </div>
                      </div>
                      <div className="w-auto">
                        <button type="submit" name="document_id" value={document.id}>herunterladen</button>
                      </div>
                    </div>
                  </ListItem>
                )
              })}
            </List>
            <input type="hidden" name="ratingpage_id" value={ratingpage ? ratingpage.id : 0} />
          </form>
        </Container>
      </Section> 
    </>
  )
}

export default Dokumente;
