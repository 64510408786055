import React, { useState } from "react";
import { withRouter } from "react-router-dom"
import CustomersDropdown from "../../../components/CustomersDropdown"

import { Container } from "../../../ui/Container";
import { List, ListItem } from "../../../ui/List"
import { PageHeader, PageTitle } from "../../../ui/Page"
import { Section, SectionTitle } from "../../../ui/Section";

const Bewertungstool = withRouter(({ history }) => {
  
  const [customer, setCustomer] = useState(null)
  
  const handleCustomerChange = (customer) => {
    setCustomer(customer)
  }
  
  return (
    <>
      <PageHeader>
        <PageTitle>Bewertungstool</PageTitle>
        <p>Über das Bewertungstool können Kunden Bewertungen sammeln. Für jede Bewertung über das Bewertungstool, spenden HPL-Kunden einen festgelegten Beitrag an einen ausgewählten Verein. Für die Einrichtung des Bewertungstools müssen Vereine und die Bewertungsseite hier in MyHPL angelegt werden. Anschließend wird ein Plugin installiert, welches das Bewertungstool auf der Kunden-Website einbettet.</p>
      </PageHeader>
      <Section>
        <Container>
          <CustomersDropdown onChange={handleCustomerChange} />
        </Container>
      </Section> 

      { customer !== null ? (
        <>
          <Section>
            <Container>
              <SectionTitle>0. Vorbereitung</SectionTitle>
              <p class="mb-3">Damit wir das Bewertungsportal für unsere Kunden einrichten können, benötigen wir deren Firmenlogo; die Logos, Beschreibungen und Websites der Vereine, für die gespendet werden soll; sowie die Einwilligung der Vereine, deren Logos verwenden zu dürfen.</p>
            </Container>
          </Section> 
          <Section>
            <Container>
              <SectionTitle>1. Vereine anlegen</SectionTitle>
              <p class="mb-3">Damit eine Bewertung auch einer Spende zugeordnet werden kann, müssen zunächst Vereine angelegt werden. Die angelegten Vereine werden dann im nächsten Schritt der Bewertungsseite zugeordnet</p>
              <a href={'/kunden/' + customer.id + '/vereine'} target="_blank" rel="noreferrer" class="text-primary">Vereine für <strong>{ customer.name }</strong> verwalten &rarr;</a>
            </Container>
          </Section> 
          <Section>
            <Container>
              <SectionTitle>2. Bewertungsseite anlegen</SectionTitle>
              <p class="mb-3">Als nächstes muss eine Bewertungsseite angelegt werden. Die Bewertungsseite wird später mit der Website verknüpft und beinhaltet alle Infos zu den gewählten Vereinen, dem Google MyBusiness Konto, zu dem die Bewertung zugeordnet werden soll und dem Beitrag, der bei erfolgreicher Conversion gespendet werden soll.</p>
              <a href={'/kunden/' + customer.id + '/bewertungsseiten'} target="_blank" rel="noreferrer" class="text-primary">Bewertungsseiten für <strong>{ customer.name }</strong> verwalten &rarr;</a>
            </Container>
          </Section> 
          <Section>
            <Container>
              <SectionTitle>3. Bewertungsseite mit Website verknüpfen</SectionTitle>
              <p class="mb-3">Damit die Bewertungsseite auf der Website des Kunden eingebettet werden kann, muss zunächst ein passendes Wordpress-Plugin installiert werden.</p>
              <p class="mb-3"><a href={'https://wordpress.homepage-lieferanten.de/plugins/hpl-reviews.zip'} target="_blank" rel="noreferrer" class="text-primary">HPL Reviews Plugin herunterladen &rarr;</a>
              </p>
              <p>
                Das Plugin kann in Wordpress über Plugins &gt; installieren &gt; Plugin hochladen
              </p>
            </Container>
          </Section> 
          <Section>
            <Container>
              <SectionTitle>4. Wordpress Plugin konfigurieren</SectionTitle>
              <p class="mb-3">Nachdem das Plugin installiert wurde, kann über Elementor das HPL Reviews Widget eingebunden werden. Damit das Widget weiß, welche Bewertungsseite es anzeigen soll, muss in den Widget-Einstellungen noch die ID der Bewertungsseite angegeben werden</p>
              <a href={'/kunden/' + customer.id + '/bewertungsseiten'} target="_blank" rel="noreferrer" class="text-primary">Bewertungsseiten für <strong>{ customer.name }</strong> ansehen &rarr;</a>
            </Container>
          </Section> 
          <Section>
            <Container>
              <SectionTitle>5. Einrichtung abgeschlossen</SectionTitle>
              <p class="mb-3">Die Bewertungsseite müsste jetzt im Frontend geladen werden. Wählt ein Besucher der Website einen Verein aus, startet ein Countdown. Der Besucher hat nun 30 Minuten Zeit seine Bewertung zu verfassen. Wir die Bewertung innerhalb der Zeit eingetragen, so wird sie beim nächsten Synchronisationsvorgang einer Spende zugeordnet und ist ab dann für unseren Kunden über MyHPL einsehbar.</p>
            </Container>
          </Section> 
          <Section>
            <Container>
              <SectionTitle>6. Drucksachen vorbereiten</SectionTitle>
              <p class="mb-3">Damit unsere Kunden ihr neues Bewertungstool bewerben können, müssen im nächsten Schritt einige Drucksachen in den Druck gegeben werden.</p>
              <a href={'/kunden/' + customer.id + '/dokumente'} target="_blank" rel="noreferrer" class="text-primary">Drucksachen für <strong>{ customer.name }</strong> ansehen &rarr;</a>
            </Container>
          </Section> 
        </>
      ) : null}
    </>
  )
})

export default Bewertungstool;
