import React, { useState, useEffect } from "react";
import { getRole } from "../../helpers";
import { Container } from "../../ui/Container";
import CmpDashboard from "../CmpDashboard";

function CmpProjectPreview() {
  const [roleData, setRole] = useState([]);

  useEffect(() => {
    getRole().then((result) => setRole(result));
  }, []);

  return (
    <div className="pt-14">
      {roleData === "admin" ? (
        <div>
          <CmpDashboard />
        </div>
      ) : (
        <div>
          <Container>
            <h1>Auf diese Ansicht haben Sie keinen Zugriff!</h1>
          </Container>
        </div>
      )}
    </div>
  );
}

export default CmpProjectPreview;
