import React from "react";
import { useEffect, useState } from "react";
import "../App.css";

//import RatingpagesDropdown from "../components/RatingpagesDropdown"
//import { formatDate } from "../helpers"
import { Container } from "../ui/Container";
import { List, ListItem } from "../ui/List";
import { PageHeader, PageTitle } from "../ui/Page";
import { Section } from "../ui/Section";
import { Alert } from "../ui/Alert";
//import { Stars } from "../ui/Stars"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faWrench } from "@fortawesome/free-solid-svg-icons";
function Spenden() {
  const [reports, setReports] = useState([]);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    async function getReports() {
      try {
        if (localStorage.getItem("user-info")) {
          await fetch(process.env.REACT_APP_API_URL + "/reports/donations", {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization:
                "Bearer " +
                JSON.parse(localStorage.getItem("user-info"))["token"],
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then((res) => res.json())
            .then((res) => {
              setReports(res);
            });
        }
      } catch (e) {
        console.log(e);
        setErrorState(true);
      }
    }
    getReports();
  }, []);

  return (
    <>
      <PageHeader>
        <PageTitle>Spenden</PageTitle>
        <p>
          Nachfolgend erhalten Sie eine Übersicht über die zu leistenden
          Spenden. Die Spendenbeiträge sollten immer zum Ende eines Zeitraums
          überwiesen werden.
        </p>
      </PageHeader>
      <Section>
        <Container>
          {errorState ? (
            <Alert type="secondary">
              Es besteht ein Problem im Spendenmodul. Versuchen Sie es später
              noch einmal.
            </Alert>
          ) : (
            <div>
              {reports.map((report, index) => (
                <List key={"report-" + index} className="mt-8">
                  <ListItem>
                    <strong>{report.name}</strong>
                  </ListItem>
                  {report.data.map((row, rowIndex) => (
                    <ListItem key={"report-" + index + "-row-" + rowIndex}>
                      {row[0] + ": " + row[1]} &euro;
                    </ListItem>
                  ))}
                </List>
              ))}

              {reports.length === 0 ? (
                <List className="mt-8">
                  <ListItem>
                    <strong>Keine Berichte vorhanden</strong>
                  </ListItem>
                </List>
              ) : null}
            </div>
          )}
        </Container>
      </Section>
    </>
  );
}

export default Spenden;
