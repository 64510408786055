import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import { Card, CardBody } from "../ui/Card";
import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { faMinusSquare } from "@fortawesome/free-regular-svg-icons";

import "../App.css";
import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { LeadText } from "../ui/LeadText";
import { Alert } from "../ui/Alert";

SwiperCore.use([Navigation, Pagination]);

function CmpDigitalstategy({ CurrentProject }) {
  const [dMounted, setDMounted] = useState(false);
  const [pdMounted, setPDMounted] = useState(false);
  const [projectDigitalstrategiesData, setProjectDigitalstrategiesData] =
    useState([]);
  const [digitalstrategiesData, setDigitalstrategiesData] = useState([]);
  const [digitalstrategiesProgress, setDigitalstrategiesProgress] = useState(0);

  useEffect(() => {
    getDigitalstrategiesData();
    getProjectDigitalstrategiesData();

    async function getDigitalstrategiesData() {
      if (localStorage.getItem("user-info")) {
        var res = await fetchData(
          process.env.REACT_APP_API_URL +
            "/projects/" +
            CurrentProject.id +
            "/digitalstrategies",
          {
            method: "GET",
          }
        ).then((data) =>
          data.json().catch((error) => {
            console.log("req failed", error);
          })
        );
        setDigitalstrategiesData(res.data);
        setDMounted(true);
      }
    }
    async function getProjectDigitalstrategiesData() {
      if (localStorage.getItem("user-info")) {
        var res = await fetchData(
          process.env.REACT_APP_API_URL +
            "/projects/" +
            CurrentProject.id +
            "/projectdigitalstrategies",
          {
            method: "GET",
          }
        ).then((data) =>
          data.json().catch((error) => {
            console.log("req failed", error);
          })
        );
        var result = Object.values(res.data);
        result.sort((a, b) => (a.step > b.step ? 1 : b.step > a.step ? -1 : 0));
        setProjectDigitalstrategiesData(result);
        getProjectDigitalstrategiesProgress(result);
        setPDMounted(true);
      }
    }
  }, [CurrentProject.id]);

  function getProjectDigitalstrategiesProgress(array) {
    var cc = 0;
    for (var ii = 0; ii < array.length; ii++) {
      if (array[ii].status === "done") {
        cc++;
      }
    }
    setDigitalstrategiesProgress(cc * (100 / array.length));
  }

  const ProgressBar = ({ progressPercentage }) => {
    return (
      <div className="h-1 w-full bg-gray-300 rounded">
        <div
          style={{ width: `${progressPercentage}%` }}
          className={"h-full bg-hpl rounded"}
        ></div>
      </div>
    );
  };

  //logic to display offeredProducts of websites
  const digitalstrategies = projectDigitalstrategiesData.map(
    (projectDigitalstrategy, id) => {
      if (dMounted && pdMounted) {
        var content = digitalstrategiesData
          .filter((ds) => ds.id === projectDigitalstrategy.digitalstrategy_id)
          .map((ds) => ds.content);
        const storedState = convertFromRaw(JSON.parse(content));
        const contentState = EditorState.createWithContent(storedState);
        return (
          <SwiperSlide
            key={"project-" + CurrentProject.id + "-digitalstrategies-" + id}
            className="px-10 pb-8"
          >
            <div className="">
              <div className="relative">
                <div className="text-xl font-bold mb-4">
                  {projectDigitalstrategy.step} &nbsp;
                  {digitalstrategiesData
                    .filter(
                      (ds) =>
                        ds.id === projectDigitalstrategy.digitalstrategy_id
                    )
                    .map((ds) => ds.title)}
                </div>
                <div className="absolute right-4 top-0">
                  {projectDigitalstrategy.status === "done" ? (
                    <FontAwesomeIcon
                      icon={faCheckSquare}
                      size="2x"
                      className="text-hpl"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faMinusSquare}
                      size="2x"
                      className="text-hpl"
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <div>
                <Editor
                  value={contentState}
                  editorState={contentState}
                  readOnly={true}
                />{" "}
              </div>
            </div>
          </SwiperSlide>
        );
      } else {
        return (
          <SwiperSlide className="px-10 pb-8">
            <div className="relative">
              <div className="text-xl font-bold mb-4">Loading...</div>
            </div>
          </SwiperSlide>
        );
      }
    }
  );

  return (
    <div className="">
      {dMounted && pdMounted ? (
        <div>
          <Section>
            <Container>
              <SectionTitle>Digitalstrategie umgesetzt</SectionTitle>
              <Alert>Achtung! Alpha-Version der Digitalstrategien.</Alert>
              <br />
              <LeadText>
                Die Digitalstrategie wurde eigens für Sie von uns
                zusammengestellt. Hier können Sie die erreichten und zukünftigen
                Ziele einsehen.
              </LeadText>
              <div className="">
                <div className="mb-8 text-3xl font-bold text-hpl">
                  {Math.round(digitalstrategiesProgress)}%
                  <ProgressBar progressPercentage={digitalstrategiesProgress} />
                </div>

                <Card className="">
                  <CardBody className="">
                    <div className="mb-4 text-2xl font-bold ">
                      Ihre Schritte:
                    </div>
                    <div className="">
                      <Swiper
                        className=""
                        spaceBetween={0}
                        slidesPerView={"auto"}
                        slidesPerGroup={1}
                        navigation={{ clickable: true }}
                        pagination={{ clickable: true }}
                      >
                        {digitalstrategies}
                      </Swiper>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Container>
          </Section>
        </div>
      ) : (
        <div>
          <Container>Loading...</Container>
        </div>
      )}
    </div>
  );
}
export default CmpDigitalstategy;
