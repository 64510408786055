import React, { useState, useEffect } from "react";
import { fetchData, getRole, getUserDetails } from "../../helpers";
import { Link } from "react-router-dom";
import { CardBody } from "../../ui/Card";
import { Button } from "../../ui/Button";
import { Container } from "../../ui/Container";
import { Section } from "../../ui/Section";
import { Modal, ModalHeader } from "../../ui/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "../../ui/Alert";

function Kunden() {
  const [mounted, setMounted] = useState(false);
  const [roleData, setRole] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stateUserName, setStateUserName] = useState("");
  const [stateUserEmail, setStateUserEmail] = useState("");
  const [stateUserRole, setStateUserRole] = useState("user");
  const [stateUserPassword, setStateUserPassword] = useState("");
  const [customersData, setCustomers] = useState([]);
  const [errorList, setErrorList] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorState, setErrorState] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    try {
      getRole().then((result) => setRole(result));

      getCustomers();
    } catch {
      setErrorState(true);
    }
  }, []);

  async function getCustomers() {
    var cuData = [];
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/user/list", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          for (var ii = 0; ii < data.data.length; ii++) {
            cuData.push(data.data[ii]);
          }
          setCustomers(cuData);
          setMounted(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  //post User function
  function postUser(event) {
    event.preventDefault();
    var userObj = {};
    let errors = [];
    userObj.name = stateUserName;
    userObj.email = stateUserEmail;
    userObj.password = stateUserPassword;
    userObj.role = stateUserRole;

    /**
     * post User
     */

    fetchData(process.env.REACT_APP_API_URL + "/register", {
      method: "POST",
      body: JSON.stringify(userObj),
    })
      .then((response) => response.json())
      .then(function (data) {
        console.log(data);
        if (data.ok) {
          return data;
        }
        if (data.token) {
          setSuccessMessage("Nutzer wurde angelegt ");
        }
        console.log(data.errors);
        errors.push(data.errors);
        console.log(errors);
        setErrorList(data.errors || {});
      })
      .then((response) => getCustomers())
      .catch((errors) => {
        alert(
          "Leider ist ein Fehler aufgetreten! Versuchen Sie es später erneut."
        );
      });
  }
  //handle dropdown change
  function handleSelectChange(event) {
    setStateUserRole(event.target.value);
  }

  //onSelect listener expected function
  function listenerStateUserRole() {
    return stateUserRole;
  }

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  //Validation Handling
  function validationStatus(errorField) {
    if (errorList[errorField])
      return (
        <div className="text-red-900 bg-red-200 rounded-sm -m-0.5 px-4">
          <span className="">{errorList[errorField]}</span>
        </div>
      );
  }
  //success visibility
  let visibility = successMessage == 0 ? "hidden" : "inline";

  //reset form
  const resetInputFields = () => {
    setStateUserEmail("");
    setStateUserPassword("");
    setStateUserName("");
    setSuccessMessage("");
  };

  const CustomerTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(props.customers);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <table className="min-w-full divide-y divide-gray-400 px-6 py-3">
        <thead className="">
          <tr>
            <th
              scope="col"
              className="w-7/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Nutzer
              </button>
            </th>
            <th
              scope="col"
              className="w-4/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("role")}
                className={getClassNamesFor("role")}
              >
                Rolle
              </button>
            </th>
            <th className="w-1/12 px-6 py-4 text-left text-xl font-medium text-gray-500"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {items
            .filter((itemData) => {
              if (searchTerm == "") {
                return itemData;
              } else if (
                itemData.name
                  .toLowerCase()
                  .includes(
                    searchTerm.toLowerCase() ||
                      itemData.email
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
              ) {
                return itemData;
              }
            })
            .map((customer, id) => (
              <tr key={"customer-" + id}>
                <td className="px-6 py-4 whitespace-nowrap">{customer.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{customer.role}</td>
                <td className="px-6 py-4 whitespace-nowrap float-right">
                  <Link to={"/kunden/" + customer.id}>
                    <Button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-pencil-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                      </svg>
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {errorState ? (
        <div>
          <Section>
            <Container>
              <Alert type="secondary">
                Serverfehler! Etwas ist schief gelaufen. Informieren Sie einen
                Entwickler.
              </Alert>
            </Container>
          </Section>
        </div>
      ) : (
        <div>
          {roleData === "admin" ? (
            <div className="">
              {mounted ? (
                <Section>
                  <Container>
                    <Modal open={isOpen} className="">
                      <ModalHeader
                        heading="Neuer Nutzer"
                        onClose={() => {
                          setIsOpen(false);
                          resetInputFields();
                        }}
                      />

                      <CardBody className="">
                        <div className="mb-2">
                          {validationStatus("name")}
                          {validationStatus("password")}
                          {validationStatus("email")}
                        </div>
                        <form>
                          <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="email"
                              >
                                E-Mail
                              </label>
                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="email"
                                type="text"
                                placeholder="E-Mail"
                                value={stateUserEmail}
                                onChange={(e) =>
                                  setStateUserEmail(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="role"
                              >
                                Passwort
                                <span className="text-xs font-normal">
                                  (mindestens 8 Zeichen)
                                </span>
                              </label>

                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="role"
                                type="password"
                                placeholder="Passwort"
                                value={stateUserPassword}
                                onChange={(e) =>
                                  setStateUserPassword(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="userName"
                              >
                                Name
                              </label>

                              <input
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="userName"
                                type="text"
                                placeholder="Name"
                                value={stateUserName}
                                onChange={(e) =>
                                  setStateUserName(e.target.value)
                                }
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="role"
                              >
                                Rolle
                              </label>
                              <select
                                className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
                                id="grid-state"
                                onSelect={listenerStateUserRole}
                                onChange={handleSelectChange}
                              >
                                <option value="user" defaultValue>
                                  User
                                </option>
                                <option value="admin">Admin</option>
                              </select>
                            </div>
                          </div>
                          <p className="text font-semibold rounded mb-1 px-0">
                            {successMessage}{" "}
                            <span className={visibility}>
                              <FontAwesomeIcon
                                icon={faCheckDouble}
                                size="lg"
                                className="text-success"
                              />
                            </span>
                          </p>
                          <Button
                            variant="primary"
                            type=""
                            className=""
                            onClick={postUser}
                          >
                            Nutzer anlegen
                          </Button>
                        </form>
                      </CardBody>
                    </Modal>
                    <div className="flex mb-2">
                      <div className="w-10/12">
                        <h1 className="text-4xl font-bold mb-8">
                          Kunden / Nutzer&nbsp;
                        </h1>
                      </div>
                      <div className="w-2/12">
                        <Button onClick={() => setIsOpen(true)} className="">
                          <FontAwesomeIcon icon={faPlus} className="" />
                        </Button>
                      </div>
                    </div>
                    <div className="flex mb-8 flex-row-reverse">
                      <div className="w-2/12 ">
                        <input
                          type="text"
                          className="text-hpl bg-hpl_bg hover:bg-gray-300 hover:text-gray-800 border-none rounded w-full"
                          placeholder="Suche..."
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <CustomerTable customers={customersData} />
                    </div>
                  </Container>
                </Section>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          ) : (
            <Section>
              <Container>
                <Alert type="secondary">
                  Auf diese Ansicht haben Sie keinen Zugriff!
                </Alert>
              </Container>
            </Section>
          )}
        </div>
      )}
    </div>
  );
}

export default Kunden;
