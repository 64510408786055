import { Container } from "./Container"

export function PageHeader({ children }) {
  return <div className="border-b py-8">
      <Container>
        { children }
      </Container>
    </div>
}

export function PageTitle({ children }) {
  return <h1 className="text-4xl font-bold mb-8">{ children }</h1>
}
