export const EtrackerLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 283 302"
      style={{ width: "inherit", height: "inherit", display: "inline-block" }}
    >
      <path
        id="Auswahl"
        fill="#53595F"
        stroke="#53595F"
        strokeWidth="1"
        d="M 243.00,219.00
           C 243.00,219.00 235.21,258.00 235.21,258.00
             234.32,262.21 233.13,271.41 230.44,274.47
             228.05,277.19 220.50,280.50 217.00,282.00
             206.53,286.49 195.96,290.29 185.00,293.42
             155.53,301.83 138.13,302.35 108.00,302.00
             83.96,301.72 55.06,292.05 36.00,277.50
             13.87,260.60 0.04,230.79 0.00,203.00
             0.00,203.00 0.00,184.00 0.00,184.00
             0.10,175.45 2.69,159.56 4.58,151.00
             18.98,85.67 66.34,26.16 132.00,7.43
             132.00,7.43 155.00,2.27 155.00,2.27
             199.80,-4.64 260.27,1.45 278.31,51.00
             283.27,64.65 283.16,72.89 283.00,87.00
             282.65,116.92 263.13,144.16 240.00,161.37
             212.36,181.94 180.63,188.79 147.00,191.09
             147.00,191.09 135.00,192.00 135.00,192.00
             119.26,192.02 97.17,190.02 82.00,186.00
             82.00,195.53 82.63,202.95 86.06,212.00
             102.74,255.93 156.86,248.89 193.00,238.57
             193.00,238.57 213.00,231.80 213.00,231.80
             213.00,231.80 243.00,219.00 243.00,219.00 Z
           M 90.00,137.00
           C 100.16,140.35 115.26,141.12 126.00,141.00
             131.50,140.93 142.43,139.29 148.00,138.20
             177.62,132.39 206.21,117.10 206.99,83.00
             207.34,67.38 201.45,58.45 186.00,54.48
             180.83,53.15 176.31,52.61 171.00,53.44
             145.43,57.43 128.85,70.55 113.63,91.00
             99.89,109.46 98.06,116.73 90.00,137.00 Z"
      />
    </svg>
  );
};
