import { Bar } from "react-chartjs-2";
import { Card, CardBody, CardAccessory } from "../ui/Card";
import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Container } from "../ui/Container";
import { SectionTitle } from "../ui/Section";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import "../App.css";
import { Alert } from "../ui/Alert";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function CmpAddedValueGraph({ CurrentProject }) {
  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const [addedValuesPerMonth, setAddedValuesPerMonth] = useState([]);
  const [customPrices] = useState(CurrentProject.custom_prices);
  const [mountedD, setMountedD] = useState(false);

  const chartData = {
    labels: addedValuesPerMonth.map(
      (addedV) => monthNames[addedV.monthIndex - 1]
    ),
    datasets: [
      {
        label: "Erwirtschafteter Mehrwert in Euro",
        data: addedValuesPerMonth.map(
          (addedV) =>
            addedV.data.UNIQUE_VISITORS * customPrices.UNIQUE_VISITORS +
            //addedV.data.SHOP * customPrices.SHOP +
            addedV.data.LEADS * customPrices.LEADS +
            addedV.data.SALES_CALLS * customPrices.SALES_CALLS +
            //addedV.data.EMAILS * customPrices.EMAILS
            getCustomsCustomAddedValues(addedV.data.CUSTOM)
        ),
        backgroundColor: ["rgba(0, 27, 190, .5)"],
        borderColor: ["rgba(0, 27, 190, 1.0)"],
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    try {
      getAddedValuesPerMonths();
    } catch {}
  }, []);

  async function getAddedValuesPerMonths() {
    if (localStorage.getItem("user-info")) {
      var res = await fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/trackings-timeline",
        {
          method: "GET",
        }
      ).then((response) => response.json());
      setAddedValuesPerMonth(res.data);
      setMountedD(true);
    }
  }

  function getCustomsCustomAddedValues(av) {
    var result = 0;
    var entries = Object.entries(av);
    for (var cAddedV of entries) {
      result =
        result +
        cAddedV[1] * customPrices["CUSTOM-" + cAddedV[0].split("-")[0]];
    }
    return result;
  }

  return (
    <div>
      <SectionTitle>
        So entwickelt sich der Mehrwert im letzten halben Jahr
      </SectionTitle>
      <Card>
        <CardAccessory>
          <Tippy
            content={
              <div>
                Das Diagramm zeigt den Verlauf des erwirtschafteten Mehrwert des
                Projekts.
              </div>
            }
          >
            <div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="lg"
                className="text-hpl"
              />
            </div>
          </Tippy>
        </CardAccessory>
        <CardBody>
          {mountedD ? (
            <></>
          ) : (
            <div className="flex">
              <div className="flex">
                <Alert>Diagramm wird geladen...</Alert>
              </div>
            </div>
          )}
          <Bar data={chartData} options={{ responsive: true }} />
        </CardBody>
      </Card>
    </div>
  );
}
export default CmpAddedValueGraph;
