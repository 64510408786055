import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import { Card, CardHeader, CardTitle, CardBody, CardText } from "../ui/Card";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import "../App.css";
import { LeadText } from "../ui/LeadText";

function CmpAutomation({ CurrentProject }) {
  const [oMounted, setOMounted] = useState(false);
  const [bMounted, setBMounted] = useState(false);
  const [offeredAutomationsData, setOfferedAutomations] = useState([]);
  const [offeredProductsData, setOfferedProducts] = useState([]);
  const [bookedProductsData, setBookedProducts] = useState([]);

  useEffect(() => {
    getOfferedAutomations();
    getBookedProducts();

    async function getOfferedAutomations() {
      var sortedAData = [];
      var sortedPData = [];
      if (localStorage.getItem("user-info")) {
        await fetchData(
          process.env.REACT_APP_API_URL +
            "/projects/" +
            CurrentProject.id +
            "/offeredProducts",
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then(function (response) {
            for (var ii = 0; ii < response.data.length; ii++) {
              if (response.data[ii]["category"] === "Automatisierung") {
                sortedAData.push(response.data[ii]);
              } else {
                sortedPData.push(response.data[ii]);
              }
            }
            setOfferedAutomations(sortedAData);
            setOfferedProducts(sortedPData);
            setOMounted(true);
          })
          .catch((error) => {
            console.log("req failed", error);
          });
      }
    }

    async function getBookedProducts() {
      var sortedBData = [];
      if (localStorage.getItem("user-info")) {
        await fetchData(
          process.env.REACT_APP_API_URL +
            "/projects/" +
            CurrentProject.id +
            "/bookedProducts",
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then(function (response) {
            for (var ii = 0; ii < response.data.length; ii++) {
              sortedBData.push(response.data[ii]);
            }
            setBookedProducts(sortedBData);
            setBMounted(true);
          })
          .catch((error) => {
            console.log("req failed", error);
          });
      }
    }
  }, [CurrentProject.id]);

  //logic to display offeredAutomations of websites
  const offeredAutomations = offeredAutomationsData.map((product, id) => {
    var content = product.content;
    const storedState = convertFromRaw(JSON.parse(content));
    const contentState = EditorState.createWithContent(storedState);
    return (
      <div
        key={"project-" + CurrentProject.id + "-offeredAutomations-" + id}
        className="mt-4"
      >
        <Card className="">
          <CardHeader>
            <CardTitle>{product.title}</CardTitle>
            {product.category}
          </CardHeader>
          <CardBody>
            <CardText>
              <Editor
                value={contentState}
                editorState={contentState}
                readOnly={true}
              />{" "}
            </CardText>
          </CardBody>
        </Card>
      </div>
    );
  });

  //logic to display offeredProducts of websites
  const offeredProducts = offeredProductsData.map((product, id) => {
    var content = product.content;
    const storedState = convertFromRaw(JSON.parse(content));
    const contentState = EditorState.createWithContent(storedState);
    return (
      <div
        key={"project-" + CurrentProject.id + "-offeredProducts-" + id}
        className="mt-4"
      >
        <Card className="">
          <CardHeader>
            <CardTitle>{product.title}</CardTitle>
            {product.category}
          </CardHeader>
          <CardBody>
            <CardText>
              <Editor
                value={contentState}
                editorState={contentState}
                readOnly={true}
              />{" "}
            </CardText>
          </CardBody>
        </Card>
      </div>
    );
  });

  //logic to display bookedProducts of websites
  const bookedProducts = bookedProductsData.map((product, id) => {
    var content = product.content;
    const storedState = convertFromRaw(JSON.parse(content));
    const contentState = EditorState.createWithContent(storedState);
    return (
      <div
        key={"project-" + CurrentProject.id + "-bookedProducts-" + id}
        className="mt-4"
      >
        <Card className="">
          <CardHeader>
            <CardTitle>{product.title}</CardTitle>
            {product.category}
          </CardHeader>
          <CardBody>
            <CardText>
              <Editor
                value={contentState}
                editorState={contentState}
                readOnly={true}
              />{" "}
            </CardText>
          </CardBody>
        </Card>
      </div>
    );
  });

  return (
    <div className="">
      {oMounted && bMounted ? (
        <div>
          <Section>
            <Container>
              <SectionTitle>Automatisierungsideen</SectionTitle>
              <LeadText>
                Die Automatisierungsideen wurden für Sie von uns
                zusammengestellt.
              </LeadText>
              {offeredAutomations}
              {offeredProducts.length > 0 ? (
                <div className="mt-12 mb-8">
                  <SectionTitle>Andere Angebote</SectionTitle>
                  <LeadText>
                    Hier werden andere Angebote aufgelistet, die Ihre
                    Online-Präsenz verbessern können.
                  </LeadText>
                  {offeredProducts}
                </div>
              ) : (
                <></>
              )}
              {bookedProducts.length > 0 ? (
                <div className="mt-12 mb-8">
                  <SectionTitle>Bereits gebuchte Angebote</SectionTitle>
                  <LeadText>
                    Hier finden Sie Angebote, die wir schon für Sie umgesetzt
                    haben.
                  </LeadText>
                  {bookedProducts}
                </div>
              ) : (
                <></>
              )}
            </Container>
          </Section>
        </div>
      ) : (
        <div>
          <Container>Loading...</Container>
        </div>
      )}
    </div>
  );
}
export default CmpAutomation;
