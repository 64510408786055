import "../App.css";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails } from "../helpers";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import CmpTickets from "../components/CmpTickets";
import { Alert } from "../ui/Alert";

function Tickets() {
  const [mounted, setMounted] = useState(false);
  const [projectsData, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState();
  const [errorCode, setErrorCode] = useState(0);
  const [errorState, setErrorState] = useState(false);
  useEffect(() => {
    getProjects();
  }, []);

  async function getProjects() {
    var data = [];
    var result;
    if (getUserDetails()) {
      try {
        result = await fetchData(
          process.env.REACT_APP_API_URL +
            "/user/" +
            JSON.parse(localStorage.getItem("user-details"))["id"] +
            "/projects",
          {
            method: "GET",
          }
        )
          .then((res) => {
            setErrorCode(res.status);
            return res.json();
          })
          .catch((error) => {
            console.log("req failed", error);
          });
        for (var ii = 0; ii < result.projects.length; ii++) {
          data.push(result.projects[ii]);
        }
        setProjects(data);
        var cur = data[0];
        setCurrentProject(cur);
        setMounted(true);
      } catch (e) {
        console.log(e);
        setErrorState(true);
      }
    }
  }

  const projectsDropdownData = projectsData.map((project, id) => {
    return (
      <option key={"project-dropdown-id-" + id} value={project.id}>
        {project.name}
      </option>
    );
  });

  //handle dropdown change
  function handleSelectChange(event) {
    setCurrentProject(
      projectsData.filter((element) => {
        return element.id === parseInt(event.target.value);
      })[0]
    );
  }

  return (
    <div className="pt-14">
      {errorState ? (
        <Section>
          <Container>
            <Alert type="secondary">
              Etwas ist schief gelaufen. Versuchen Sie es später noch einmal.
            </Alert>
          </Container>
        </Section>
      ) : (
        <div>
          {errorCode >= 400 ? (
            <Section>
              <Container>
                <SectionTitle>{errorCode}</SectionTitle>
                <h1>
                  Etwas ist schief gelaufen. Versuchen Sie es später noch
                  einmal.
                </h1>
              </Container>
            </Section>
          ) : (
            <div>
              {mounted ? (
                <div>
                  <Container>
                    {projectsData !== undefined ? (
                      <select
                        className="rounded-md p-2.5 w-full bg-hpl_bg text-hpl font-bold hover:bg-gray-300 hover:text-gray-800 border-none text-center text-xl"
                        name="project"
                        defaultValue={currentProject.id}
                        selected={currentProject.id}
                        onChange={handleSelectChange}
                      >
                        {projectsDropdownData}
                      </select>
                    ) : (
                      <>
                        <p>Kein Projekt gefunden!</p>
                      </>
                    )}
                  </Container>
                  <CmpTickets CurrentProject={currentProject} />
                </div>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Tickets;
