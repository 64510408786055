import { config } from "@fortawesome/fontawesome-svg-core"
import { faPencilAlt, faPlus, faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Button } from "../../ui/Button"
import { Container } from "../../ui/Container"
import { Label } from "../../ui/Label"
import { List, ListItem } from "../../ui/List"
import { Section } from "../../ui/Section"
import { Textarea } from "../../ui/Textarea"
import { TextInput } from "../../ui/TextInput"

function Vereine() {
  const [clubs, setClubs] = useState([])
  const [activeClub, setActiveClub] = useState(null)

  const { id } = useParams()
  
  useEffect(() => {

    fetch(process.env.REACT_APP_API_URL + '/users/' + id + '/clubs', {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setClubs(res)
    })

  }, [])

  const handleCreateClub = () => {

    var args = {
      user_id: +id,
      name: 'Neuer Verein',
      description: '',
      url: '',
    }
    
    fetch(process.env.REACT_APP_API_URL + '/clubs', {
      method: 'POST',
      body: JSON.stringify(args),
      headers: {
        'Content-Type': "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setClubs([res, ...clubs])
      setActiveClub({ ...res })
    })
  }

  const handleEditClub = (club) => {
    setActiveClub({ ...club })
  }
  
  const handleDeleteClub = (club) => {

    // eslint-disable-next-line
    if (!confirm(`Möchten Sie ${club.name} wirklich löschen?`))
      return

    const clubId = club.id
      
    fetch(process.env.REACT_APP_API_URL + '/clubs/' + clubId, {
      method: 'DELETE',
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setClubs(clubs.filter(club => club.id !== clubId))
    })
  }

  const handleUpdateActiveClub = () => {
    const clubId = activeClub.id

    const formData = new FormData()
    formData.append('_method', 'PUT') // setting _method=PUT and the actual fetch method to POST is neccesary to use form-data with laravel
    formData.append('name', activeClub.name)
    formData.append('description', activeClub.description || '')
    formData.append('url', activeClub.url || '')

    if (activeClub.thumbnail) {
      formData.append('thumbnail', activeClub.thumbnail)
    }

    if (activeClub.logo) {
      formData.append('logo', activeClub.logo, activeClub.logo.name)
    }
        
    fetch(process.env.REACT_APP_API_URL + '/clubs/' + clubId, {
      method: 'POST',
      body: formData,
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setActiveClub(null)
      setClubs(clubs.map(club => {
        return club.id === clubId ? res : club
      }))
    })
  }

  const handleInputChange = (event) => {
    let value = event.target.value
    
    if (event.target.type === 'file') {
      value = event.target.files[0]
    }
    
    activeClub[event.target.name] = value
    setActiveClub({ ...activeClub })
  }

  const handleDeleteActiveClubThumbnail = (event) => {
    event.preventDefault()
    activeClub['thumbnail'] = null
    setActiveClub({ ...activeClub })
  }

  return (
    <>
      <Section>
        <Container>
          <List>
            <ListItem>
              <Button onClick={handleCreateClub}>Verein hinzufügen <FontAwesomeIcon icon={faPlus} className="" /></Button>
            </ListItem>
            { clubs.map(club => (
              <ListItem key={'club-' + club.id}>
                

                  { activeClub && club.id === activeClub.id ? (
                    <div>
                      <div className="mb-4">
                        <Label>Name</Label>
                        <TextInput placeholder="Name" name="name" value={activeClub.name} onChange={handleInputChange} />
                      </div>
                      <div className="mb-4">
                        <Label>Beschreibung</Label>
                        <Textarea placeholder="Beschreibung" name="description" onChange={handleInputChange} value={activeClub.description || ''}></Textarea>
                      </div>
                      <div className="mb-4">
                        <Label>Website</Label>
                        <TextInput placeholder="https://example.com" name="url" onChange={handleInputChange} value={activeClub.url || ''}></TextInput>
                      </div>
                      <div className="mb-4">
                        <Label>Logo</Label>
                        { activeClub.thumbnail ? (
                          <>
                            <img src={activeClub.thumbnail} alt="" className="w-32 h-auto" />
                            <a href="#" onClick={handleDeleteActiveClubThumbnail}>Entfernen</a>
                          </>
                        ) : (
                          <input type="file" name="logo" onChange={handleInputChange} />
                        )}
                        <p className="text-gray mt-2 text-sm">Das Logo sollte folgende Anforderungen erfüllen: mindestens 400px Breite, mindestens 72dpi, Dateiformat: PNG</p>
                      </div>
                      <div className="flex">
                        <div className="flex-1 mr-4">
                          <Button onClick={handleUpdateActiveClub}>Änderungen speichern</Button>
                        </div>
                        <div>
                          <Button onClick={() => setActiveClub(null)} type="secondary">abbrechen</Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="w-16 mr-4">
                        <img src={club.thumbnail} alt="" className="w-100 h-auto" />
                      </div>
                      <div className="flex-1">
                        <p>
                          <strong>{ club.name }</strong>
                        </p>

                        <p>{ club.description }</p>
                      </div>
                      <div className="w-auto mr-2">
                        <Button onClick={() => handleEditClub(club)}><FontAwesomeIcon icon={faPencilAlt} /></Button>
                      </div>
                      <div className="w-auto">
                        <Button onClick={() => handleDeleteClub(club)} type="danger"><FontAwesomeIcon icon={faTrashAlt} /></Button>
                      </div>
                    </div>
                  ) }

              </ListItem>
            ))}

            { clubs.length === 0 ? (
              <ListItem>
                <strong>Keine Vereine vorhanden</strong>
              </ListItem>
            ) : null }
          </List>
        
        </Container>
      </Section> 
    </>
  )
}

export default Vereine;
