import { faPencilAlt, faPlus, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Alert } from "../../ui/Alert"
import { Button } from "../../ui/Button"
import { Container } from "../../ui/Container"
import Dropdown from "../../ui/Dropdown"
import { Label } from "../../ui/Label"
import { List, ListItem } from "../../ui/List"
import { Section } from "../../ui/Section"
import { TextInput } from "../../ui/TextInput"

function Bewertungsseitee() {
  const [errors, setErrors] = useState([])
  const [ratingpages, setRatingpages] = useState([])
  const [activeRatingpage, setActiveRatingpage] = useState(null)
  const [clubs, setClubs] = useState([])

  const { id } = useParams()
  
  useEffect(() => {

    fetch(process.env.REACT_APP_API_URL + '/users/' + id + '/ratingpages', {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setRatingpages(res)
    })
    
    fetch(process.env.REACT_APP_API_URL + '/users/' + id + '/clubs', {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setClubs(res)
    })

  }, [])

  const handleCreateRatingpage = () => {

    var args = {
      user_id: +id,
      name: 'Neue Bewertungsseite',
      place_id: '',
      amount: 0.0,
    }
    
    fetch(process.env.REACT_APP_API_URL + '/ratingpages', {
      method: 'POST',
      body: JSON.stringify(args),
      headers: {
        'Content-Type': "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setRatingpages([res.data, ...ratingpages])
      setActiveRatingpage({ ...res.data })
    })
  }

  const handleEditRatingpage = (ratingpage) => {
    console.log(ratingpage)
    setActiveRatingpage({ ...ratingpage })
  }
  
  const handleDeleteRatingpage = (ratingpage) => {

    // eslint-disable-next-line
    if (!confirm(`Möchten Sie ${ratingpage.name} wirklich löschen?`))
      return

    const ratingpageId = ratingpage.id
      
    fetch(process.env.REACT_APP_API_URL + '/ratingpages/' + ratingpageId, {
      method: 'DELETE',
      headers: {
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      setRatingpages(ratingpages.filter(ratingpage => ratingpage.id !== ratingpageId))
    })
  }

  const handleUpdateActiveRatingpage = () => {
    setErrors([])

    const ratingpageId = activeRatingpage.id

    const args = {
      name: activeRatingpage.name,
      slug: activeRatingpage.slug,
      place_id: activeRatingpage.place_id,
      amount: activeRatingpage.amount,
      clubs: activeRatingpage.clubs.map(club => club.id),
    }
        
    fetch(process.env.REACT_APP_API_URL + '/ratingpages/' + ratingpageId, {
      method: 'PATCH',
      body: JSON.stringify(args),
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
      },
    })
    .then(res => res.json())
    .then(res => {
      if (res.status === 'error') {
        setErrors(res.errors)
        return
      }

      setErrors([])
      setActiveRatingpage(null)
      setRatingpages(ratingpages.map(ratingpage => {
        return ratingpage.id === ratingpageId ? res.data : ratingpage
      }))
    })
  }

  const handleInputChange = (event) => {
    let value = event.target.value

    activeRatingpage[event.target.name] = value
    setActiveRatingpage({ ...activeRatingpage })
  }

  const handleAddClubToActiveRatingpage = (club) => {
    activeRatingpage.clubs.push(club)
    setActiveRatingpage({ ...activeRatingpage })
  }

  const handleRemoveClubFromActiveRatingpage = (club) => {
    const clubIndex = activeRatingpage.clubs.indexOf(club)
    activeRatingpage.clubs.splice(clubIndex, 1)
    setActiveRatingpage({ ...activeRatingpage })
  }

  return (
    <>
      <Section>
        <Container>
          <List>
            <ListItem>
              <Button onClick={handleCreateRatingpage}>Bewertungsseite hinzufügen <FontAwesomeIcon icon={faPlus} className="" /></Button>
            </ListItem>
            { ratingpages.map(ratingpage => (
              <ListItem key={'ratingpage-' + ratingpage.id}>
                

                  { activeRatingpage && ratingpage.id === activeRatingpage.id ? (
                    <div>
                      <div className="mb-4">
                        <Label>Name</Label>
                        <TextInput placeholder="Name" name="name" value={activeRatingpage.name} onChange={handleInputChange} />
                      </div>
                      <div className="mb-4">
                        <Label>Seiten Id</Label>
                        <TextInput placeholder="Seiten Id" name="slug" onChange={handleInputChange} value={activeRatingpage.slug} />
                        <p className="text-gray-500 mt-2">Die Seiten Id wird von Wordpress benötigt, um das Bewertungswidget einzurichten</p>
                      </div>                    
                      <div className="mb-4">
                        <Label>Spendenbeitrag</Label>
                        <TextInput type="number" placeholder="Spendenbeitrag" name="amount" onChange={handleInputChange} value={activeRatingpage.amount} />
                      </div>                 
                      <div className="mb-4">
                        <Label>Place Id</Label>
                        <TextInput placeholder="Place Id" name="place_id" onChange={handleInputChange} value={activeRatingpage.place_id} />
                        <p className="text-gray-500 mt-2">Die Place Id kann <a href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder" target="_blank" rel="noreferrer" className="text-primary">hier</a> abgerufen werden</p>
                      </div>
                      <div className="mb-4">
                        <Label>Vereine</Label>
                        <List>
                          { activeRatingpage.clubs.map((club, index) => {
                            return (
                              <ListItem key={'club-' + index}>
                                <div className="flex items-center">
                                  <div className="flex-1">
                                    <p><strong>{ club.name }</strong></p>
                                  </div>
                                  <div className="w-auto">
                                    <Button type="secondary" onClick={() => handleRemoveClubFromActiveRatingpage(club)}>
                                      <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                  </div>
                                </div>
                              </ListItem>
                            )
                          })}
                          <ListItem>
                            <Dropdown items={clubs} onChange={handleAddClubToActiveRatingpage} placeholder="Verein hinzufügen" type="secondary" />
                          </ListItem>
                        </List>
                      </div>
                      { errors.length > 0 ? (
                        <div className="mb-4">
                          <Alert type="danger">
                            { errors.map(error => error) }
                          </Alert>
                        </div>
                      ) : null }
                      <div className="flex">
                        <div className="flex-1 mr-4">
                          <Button onClick={handleUpdateActiveRatingpage}>Änderungen speichern</Button>
                        </div>
                        <div>
                          <Button onClick={() => setActiveRatingpage(null)} type="secondary">abbrechen</Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <div className="flex-1">
                        <p>
                          <strong>{ ratingpage.name } <span className="opacity-50">{ ratingpage.slug }</span></strong>
                        </p>

                        <p>{ ratingpage.place_id }</p>
                      </div>
                      <div className="w-auto mr-2">
                        <Button onClick={() => handleEditRatingpage(ratingpage)}><FontAwesomeIcon icon={faPencilAlt} /></Button>
                      </div>
                      <div className="w-auto">
                        <Button onClick={() => handleDeleteRatingpage(ratingpage)} type="danger"><FontAwesomeIcon icon={faTrashAlt} /></Button>
                      </div>
                    </div>
                  ) }

              </ListItem>
            ))}

            { ratingpages.length === 0 ? (
              <ListItem>
                <strong>Keine Bewertungsseite vorhanden</strong>
              </ListItem>
            ) : null }
          </List>
        
        </Container>
      </Section> 
    </>
  )
}

export default Bewertungsseitee;
