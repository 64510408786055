import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import { Card, CardBody, CardTitle } from "../ui/Card";
import { LeadText } from "../ui/LeadText";

function CmpRoutineCheck({ CurrentProject }) {
  var date = new Date(CurrentProject.next_routine_check);

  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "July",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];
  return (
    <div className="">
      <Section>
        <Container>
          <SectionTitle>Ihr nächster Routinetermin</SectionTitle>
          <LeadText>
            Die Routinetermine werden im Normalfall einmal pro Jahr
            durchgeführt. Das Datum wird hier aufgeführt und kann per Ticket
            verschoben werden.
          </LeadText>
          <Card className="">
            <CardBody>
              <CardTitle>
                {date.getDate() + ". " + monthNames[date.getMonth()]}
              </CardTitle>
              <p>2021</p>
            </CardBody>
          </Card>
        </Container>
      </Section>
    </div>
  );
}
export default CmpRoutineCheck;
