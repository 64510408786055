import { NavLink } from "react-router-dom"

export function Nav({ children }) {
  return <nav className="flex">
      { children }
    </nav>
}

export function NavItem({to, children}) {
  return (
    <NavLink
      exact 
      className="text-center rounded-md p-3 mr-2 whitespace-nowrap hover:bg-gray-200 hover:text-gray-500 font-semibold"
      activeClassName="bg-hpl_bg text-primary rounded-md p-3"
      to={to}
    >
      { children }
    </NavLink>
  )
}
