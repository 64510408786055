import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails, getRole } from "../../helpers";
import { Section } from "../../ui/Section";
import { Container } from "../../ui/Container";
import { Button } from "../../ui/Button";
import { Modal, ModalHeader } from "../../ui/Modal";
import { CardBody } from "../../ui/Card";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw } from "draft-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

function CmpDigitalstrategyAdmin() {
  const history = useHistory();
  var id = window.location.pathname.split("/")[2];
  const [mounted, setMounted] = useState(false);
  const [roleData, setRole] = useState([]);
  const [digitalstrategy, setDigitalstrategy] = useState([]);
  const [stateDigitalstrategyTitle, setStateDigitalstrategyTitle] = useState(
    digitalstrategy.title
  );
  const [stateDigitalstrategyCategory, setStateDigitalstrategyCategory] =
    useState(digitalstrategy.category);
  const [isOpen, setIsOpen] = useState(false);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    getRole().then((result) => setRole(result));
    getDigitalstrategy();

    async function getDigitalstrategy() {
      if (getUserDetails()) {
        var res = await fetchData(
          process.env.REACT_APP_API_URL + "/digitalstrategies/" + id,
          {
            method: "GET",
          }
        )
          .then((data) => data.json())
          .catch((error) => {
            console.log("req failed", error);
          });
      }
      setDigitalstrategy(res.data);
      const content = convertFromRaw(JSON.parse(res.data.content));
      const contentState = EditorState.createWithContent(content);
      setEditorState(contentState);
      setMounted(true);
    }
  }, [id]);

  //update digitalstrategy function
  function onSave(event) {
    event.preventDefault();

    var content = convertToRaw(editorState.getCurrentContent());

    var offerObj = {};
    offerObj.title = stateDigitalstrategyTitle;
    offerObj.category = stateDigitalstrategyCategory;
    offerObj.content = JSON.stringify(content);

    fetchData(
      process.env.REACT_APP_API_URL +
        "/digitalstrategies/" +
        digitalstrategy.id,
      {
        method: "PUT",
        body: JSON.stringify(offerObj),
      }
    )
      .then((response) => response.json())
      .then((response) => event.preventDefault());
  }

  function onDelete() {
    fetchData(
      process.env.REACT_APP_API_URL +
        "/digitalstrategies/" +
        digitalstrategy.id,
      {
        method: "DELETE",
      }
    )
      .then((response) => response.json())
      .then((response) => "Deleted!" + response);

    history.push("/digitalstrategien");
  }

  return (
    <div className="">
      {roleData === "admin" ? (
        mounted ? (
          <Section>
            <Container>
              <Modal open={isOpen} className="p-3">
                <ModalHeader
                  heading="Dieses Digitalstrategie löschen? &nbsp;"
                  onClose={() => setIsOpen(false)}
                />

                <CardBody className="">
                  <Button variant="primary" className="" onClick={onDelete}>
                    Digitalstrategie löschen
                  </Button>
                </CardBody>
              </Modal>
              <div className="flex w-full mb-8">
                <h1 className="w-10/12 text-4xl font-bold">
                  {digitalstrategy.title}&nbsp;
                </h1>{" "}
                <div className="flex-auto w-2/12 p-0 font-bold">
                  <div className="float-right w-full">
                    <Button
                      variant="primary"
                      type=""
                      className="float-right"
                      onClick={() => setIsOpen(true)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="" />
                    </Button>
                  </div>
                </div>
              </div>
              <form>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="title"
                    >
                      Titel
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border-none rounded py-3 px-4 
                    leading-tight focus:outline-none focus:border-gray-400"
                      name="title"
                      type="title"
                      placeholder={digitalstrategy.title}
                      defaultValue={digitalstrategy.title}
                      onChange={(e) =>
                        setStateDigitalstrategyTitle(e.target.value)
                      }
                    />
                  </div>
                  <div className="">
                    <label
                      className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="category"
                    >
                      Kategorie
                    </label>
                    <input
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border-none rounded py-3 px-4 
                    leading-tight focus:outline-none focus:border-gray-400"
                      name="category"
                      type="text"
                      placeholder={digitalstrategy.category}
                      defaultValue={digitalstrategy.category}
                      onChange={(e) =>
                        setStateDigitalstrategyCategory(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="">
                  <label
                    className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="content"
                  >
                    Inhalt
                  </label>
                  <div className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-400">
                    <Editor
                      id="digitalstrategyContent"
                      value={editorState}
                      editorState={editorState}
                      onEditorStateChange={onEditorStateChange}
                    />
                  </div>
                </div>
                <div className="mt-4 mb-4">
                  <hr />
                </div>
                <Button variant="primary" type="" className="" onClick={onSave}>
                  Speichern
                </Button>
              </form>
            </Container>
          </Section>
        ) : (
          <div>
            <Container>Loading...</Container>
          </div>
        )
      ) : (
        <>
          <h1>Auf diese Ansicht haben Sie keinen Zugriff!</h1>
        </>
      )}
    </div>
  );
}

export default CmpDigitalstrategyAdmin;
