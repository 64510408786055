import React from "react";
import { Card, CardHeader } from "./Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes as fasTimes } from "@fortawesome/free-solid-svg-icons";

export function Modal({ open, children }) {
  if (!open) return null;

  return (
    <div className="flex absolute bottom-10 right-10 p-3">
      <Card className="">{children}</Card>
    </div>
  );
}

export function ModalHeader({ heading, onClose }) {
  return (
    <CardHeader>
      <div className="flex flex-col rounded">
        <div className="flex flex-row justify-between p-6 bg-white border-b rounded">
          <div className="w-5/6">
            <p className="font-bold text-gray-800">{heading}</p>
          </div>
          <div>
            {" "}
            <button onClick={onClose}>
              <FontAwesomeIcon icon={fasTimes} size="2x" className="text-hpl" />
            </button>
          </div>
        </div>
      </div>
    </CardHeader>
  );
}
