import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Button } from "../ui/Button";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import { Link } from "react-router-dom";

function Register() {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role] = useState("user");

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      history.push("/uebersicht");
    }
  }, [history]);

  async function signUp() {
    let item = { name, email, password, role };
    let result = await fetchData(process.env.REACT_APP_API_URL + "/register", {
      method: "POST",
      body: JSON.stringify(item),
    });
    result = await result.json();
    localStorage.setItem("user-info", JSON.stringify(result));
    window.location.reload();
    history.push("/uebersicht");
  }

  return (
    <div>
      <Section>
        <Container>
          <h1>
            <SectionTitle>Registrieren</SectionTitle>
          </h1>
          <h2 className="mb-4">
            Aktuell können Sie sich nicht selbst registrieren. Bitte melden Sie
            sich bei uns, um einen Nutzer-Account anzulegen.
          </h2>
          <Link to="https://homepage-lieferanten.de">
            <Button>Zu den Homepage Lieferanten</Button>
          </Link>
          <div className="" style={{ display: "none" }}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=""
              placeholder="Name"
            />
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className=""
              placeholder="E-Mail"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className=""
              placeholder="Passwort"
            />

            <Button onClick={signUp}>Registrieren</Button>
          </div>
        </Container>
      </Section>
    </div>
  );
}

export default Register;
