import { useEffect, useState } from "react"
import { fetchData, getUserDetails } from "../helpers"
import Dropdown from "../ui/Dropdown"

function  CustomersDropdown(props) {
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    const userDetails = getUserDetails()

    if (userDetails === null) return

    const userId = userDetails.id

    fetchData(process.env.REACT_APP_API_URL + "/user/list")
      .then(res => res.json())
      .then(res => {
        setCustomers(res.data)
        if (res.data.length > 0) {
          props.onChange(res.data[0])
        }
      })
  }, [])

  const handleChange = (customer) => {
    props.onChange(customer)
  }
  
  return <Dropdown items={customers} onChange={handleChange} />
}

export default CustomersDropdown;
