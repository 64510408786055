import "../App.css";
import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";

import CmpAddedValue from "./CmpAddedValue";
import CmpDevices from "./CmpDevices";
import CmpVisitors from "./CmpVisitors";
import CmpKeywords from "./CmpKeywords";
import CmpTickets from "./CmpTickets";

//import CmpReference from "./CmpReference";
import CmpAutomation from "./CmpAutomation";
//import CmpNewsletter from "./CmpNewsletter";
//import CmpGoogleAds from "./CmpGoogleAds";
//import CmpSeo from "./CmpSeo";
import CmpDigitalstategy from "./CmpDigitalstrategy";
import CmpRoutineCheck from "./CmpRoutineCheck";
import { Alert } from "../ui/Alert";

function CmpDashboard() {
  const [mounted, setMounted] = useState(false);
  var id = window.location.pathname.split("/")[2];
  const [CurrentProject, setCurrentProject] = useState();
  const [projecttiles, setProjectTiles] = useState();
  const [errorCode, setErrorCode] = useState(0);
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    getCurrentProject();
  }, [id]);

  async function getCurrentProject() {
    var data = [];
    if (localStorage.getItem("user-info")) {
      await fetchData(process.env.REACT_APP_API_URL + "/projects/" + id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user-info"))["token"],
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => {
          setErrorCode(res.status);
          return res.json();
        })
        .then(function (res) {
          data = res.data;
          setCurrentProject(data);
          setProjectTiles(data.tiles);
          setMounted(true);
        })
        .catch((error) => {
          console.log("req failed", error);
          setErrorState(true);
        });
    }
  }

  return (
    <div>
      {errorState ? (
        <Section>
          <Container>
            <Alert type="secondary">
              Serverfehler! Etwas ist schief gelaufen. Informieren Sie einen
              Entwickler.
            </Alert>
          </Container>
        </Section>
      ) : (
        <div>
          {errorCode >= 400 ? (
            <Section>
              <Container>
                <SectionTitle>{errorCode}</SectionTitle>
                <h1>
                  Etwas ist schief gelaufen. Versuchen Sie es später noch
                  einmal.
                </h1>
              </Container>
            </Section>
          ) : (
            <div>
              {mounted ? (
                <div>
                  <h1 className="container text-4xl font-bold">Mehrwert</h1>
                  {projecttiles.added_value ? (
                    <div>
                      <div className="container mb-8">
                        <Alert type="secondary">
                          Viele unserer Daten werden über Google Analytics oder
                          Etracker bereitgestellt. Diese geben unter Umständen
                          nicht die exakten Zahlen wieder. Außerdem können sie
                          durch Bots verfälscht werden.
                        </Alert>
                      </div>
                      <CmpAddedValue CurrentProject={CurrentProject} />
                    </div>
                  ) : (
                    <div className="container mt-4 mb-8">
                      <Alert type="secondary">
                        Das Mehrwertmodul ist nicht freigeschaltet. Bitte wenden
                        Sie sich an Ihren Kundenbetreuer.
                      </Alert>
                    </div>
                  )}
                  <h1 className="container text-4xl font-bold">Statistiken</h1>
                  <div className="container mt-4">
                    <Alert type="secondary">
                      Viele unserer Daten werden über Google Analytics
                      bereitgestellt. Diese können unter Umständen nicht die
                      exakten Zahlen wiedergeben. Außerdem können sie durch Bots
                      verfälscht werden.
                    </Alert>
                  </div>
                  <div className="grid grid-cols-2 container">
                    {" "}
                    <div className="pr-8">
                      {projecttiles.visitors ? (
                        <CmpVisitors
                          key={"project-" + CurrentProject.id + "-visitors"}
                          CurrentProject={CurrentProject}
                        />
                      ) : (
                        <></>
                      )}
                      {projecttiles.default_keywords ? (
                        <CmpKeywords
                          key={"project-" + CurrentProject.id + "-keywords"}
                          CurrentProject={CurrentProject}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="pl-8">
                      {projecttiles.devices ? (
                        <CmpDevices
                          key={"project-" + CurrentProject.id + "-devices"}
                          CurrentProject={CurrentProject}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <h1 className="container text-4xl font-bold">Tickets</h1>
                  {projecttiles.tickets ? (
                    <CmpTickets
                      key={"project-" + CurrentProject.id + "-tickets"}
                      CurrentProject={CurrentProject}
                    />
                  ) : (
                    <></>
                  )}
                  <h1 className="container text-4xl font-bold">
                    Digitalstrategie und Angebote
                  </h1>
                  {projecttiles.digital_strategy ? (
                    <CmpDigitalstategy
                      key={"project-" + CurrentProject.id + "-digitalstrategy"}
                      CurrentProject={CurrentProject}
                    />
                  ) : (
                    <div className="container mt-4 mb-8">
                      <Alert type="secondary">
                        Das Digitalstrategienmodul ist nicht freigeschaltet.
                        Bitte wenden Sie sich an Ihren Kundenbetreuer.
                      </Alert>
                    </div>
                  )}
                  {/* {projecttiles.google_reference ? (
                    <CmpReference CurrentProject={CurrentProject} />
                  ) : (
                    <></>
                  )} */}
                  {projecttiles.automations ? (
                    <CmpAutomation
                      key={"project-" + CurrentProject.id + "-automation"}
                      CurrentProject={CurrentProject}
                    />
                  ) : (
                    <div className="container mt-4 mb-8">
                      <Alert type="secondary">
                        Das Automatisierungsmodul ist nicht freigeschaltet.
                        Bitte wenden Sie sich an Ihren Kundenbetreuer.
                      </Alert>
                    </div>
                  )}
                  {projecttiles.next_routine_check ? (
                    <CmpRoutineCheck CurrentProject={CurrentProject} />
                  ) : (
                    <></>
                  )}
                  {/* {projecttiles.newsletter ? (
            <CmpNewsletter CurrentProject={CurrentProject} />
          ) : (
            <></>
          )}
          {projecttiles.google_ads ? (
            <CmpGoogleAds CurrentProject={CurrentProject} />
          ) : (
            <></>
          )}
          {projecttiles.seo ? (
            <CmpSeo
              key={"project-" + CurrentProject.id + "-seo"}
              CurrentProject={CurrentProject}
            />
          ) : (
            <></>
          )} */}
                </div>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default CmpDashboard;
