import { useEffect, useState } from "react";
import Dropdown from "../ui/Dropdown";
import { Alert } from "../ui/Alert";
import { fetchData, getUserDetails } from "../helpers";

function RatingpagesDropdown(props) {
  const [projects, setProjects] = useState([]);
  const [errorState, setErrorState] = useState(false);

  var userId = props.userId;

  useEffect(() => {
    if (userId === undefined) {
      const user = getUserDetails();
      userId = user.id;
    }

    fetchData(
      process.env.REACT_APP_API_URL + "/users/" + userId + "/ratingpages"
    )
      .then((res) => res.json())
      .then((res) => {
        setProjects(res);
      });
  }, []);

  const handleChange = (project) => {
    props.onChange(project);
  };

  if (errorState) {
    return (
      <Alert type="secondary">
        Es besteht ein Problem im Bewertungsmodul. Versuchen Sie es später noch
        einmal.
      </Alert>
    );
  } else if (projects.length === 0) {
    return (
      <Alert type="secondary">
        Das Bewertungsmodul ist nicht freigeschaltet. Bitte wenden Sie sich an
        Ihren Kundenbetreuer.
      </Alert>
    );
  }

  return <Dropdown items={projects} onChange={handleChange} />;
}

export default RatingpagesDropdown;
