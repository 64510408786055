import React from "react";
import { withRouter } from "react-router-dom"

import { Container } from "../../ui/Container";
import { List, ListItem } from "../../ui/List"
import { PageHeader, PageTitle } from "../../ui/Page"
import { Section } from "../../ui/Section";

const Anleitungen = withRouter(({ history }) => {
  
  const guides = [
    {
      name: 'Bewertungstool',
      description: 'Hier lernst du, wie du das Bewertungstool für Kunden einrichten und auf deren Website einbinden kannst.',
      route: '/anleitungen/bewertungstool',
    },
  ]
  
  return (
    <>
      <PageHeader>
        <PageTitle>Anleitungen</PageTitle>
        <p>Hier findest du Anleitungen zu verschiedenen MyHPL-Leistungen</p>
      </PageHeader>
      <Section>
        <Container>
          <List>
            { guides.map((guide, index) => (
              <ListItem key={'guide-' + index}>
                <p>
                  <strong>{ guide.name }</strong>
                </p>

                <p className="text-gray-500 m-0">{ guide.description }</p>
                <p>
                  <button className="btn-link" onClick={() => history.push(guide.route)}>weiterlesen</button>
                </p>
              </ListItem>
            ))}
          </List>
        
        </Container>
      </Section> 
    </>
  )
})

export default Anleitungen;
