export function Card({ children }) {
  return (
    <div className="rounded-lg bg-white transition-shadow relative border border-light-grey">
      {children}
    </div>
  );
}

export function CardBody({ children }) {
  return <div className="px-6 py-3">{children}</div>;
}

export function CardTitle({ children }) {
  return <h3 className="text-3xl font-bold">{children}</h3>;
}

export function CardText({ children }) {
  return <div>{children}</div>;
}

export function CardHeader({ children }) {
  return <div className="px-6 pt-6 pb-3">{children}</div>;
}

export function CardFooter({ children }) {
  return <div className="px-6 pt-3 pb-6">{children}</div>;
}

export function CardAccessory({ children }) {
  return <div className="absolute top-0 right-0 p-6">{children}</div>;
}
