import classNames from "classnames"

export function List({ children, className }) {
  return <ul className={classNames(['border rounded', className])}>
      { children }
    </ul>
}

export function ListItem({ children }) {
  return <li className="p-4 border-b last:border-0">{ children }</li>
}
