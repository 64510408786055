import { Container } from "../../ui/Container";
import { Section } from "../../ui/Section";
import { Button } from "../../ui/Button";
import { CardBody } from "../../ui/Card";
import { Modal, ModalHeader } from "../../ui/Modal";
import React, { useState, useEffect } from "react";
import { fetchData, getRole, getUserDetails } from "../../helpers";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "../../ui/Alert";

function SelliesHighlightsZuordnung() {
  const [mountedU, setMountedU] = useState(false);
  const [mountedO, setMountedO] = useState(false);
  const [mountedUO, setMountedUO] = useState(false);
  const [roleData, setRole] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [stateUserOfferUserID, setStateUserOfferUserID] = useState("1");
  const [stateUserOfferOfferID, setStateUserOfferOfferID] = useState("1");
  const [stateUserOfferStatus, setStateUserOfferStatus] = useState("inactive");
  const [offersData, setOffersData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [userOffersData, setUserOffersData] = useState([]);
  const [errorState, setErrorState] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const onUserMenuOpen = () => setIsUserMenuOpen(true);
  const onUserMenuClose = () => setIsUserMenuOpen(false);
  const [isMenuUserOpen, setIsUserMenuOpen] = useState(false);
  const onOfferMenuOpen = () => setIsOfferMenuOpen(true);
  const onOfferMenuClose = () => setIsOfferMenuOpen(false);
  const [isOfferMenuOpen, setIsOfferMenuOpen] = useState(false);

  useEffect(() => {
    try {
      getRole().then((result) => setRole(result));
      getOffers();
      getUsers();
      getUserOffers();
    } catch {
      setErrorState(true);
    }
  }, []);

  async function getOffers() {
    var oData = [];
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/offers", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          for (var ii = 0; ii < data.length; ii++) {
            oData.push(data[ii]);
          }
          setOffersData(oData);
          setStateUserOfferOfferID(oData[0].id);
          setMountedO(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }
  async function getUsers() {
    var uData = [];
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/user/list", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (res) {
          uData = res.data;
          if (res.data.length > 0) {
            setStateUserOfferUserID(res.data[0].id);
          }
          setUsersData(uData);
          setStateUserOfferUserID(uData[0].id);
          setMountedU(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  async function getUserOffers() {
    var uoData = [];
    if (getUserDetails()) {
      await fetchData(process.env.REACT_APP_API_URL + "/useroffers", {
        method: "GET",
      })
        .then((response) => response.json())
        .then(function (data) {
          for (var ii = 0; ii < data.data.length; ii++) {
            uoData.push(data.data[ii]);
          }
          setUserOffersData(uoData);
          setMountedUO(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  //post UserOffer function
  function postUserOffer(event) {
    event.preventDefault();

    var userOfferObj = {};
    userOfferObj.user_id = stateUserOfferUserID;
    userOfferObj.offer_id = stateUserOfferOfferID;
    userOfferObj.status = stateUserOfferStatus;

    fetchData(process.env.REACT_APP_API_URL + "/useroffers", {
      method: "POST",
      body: JSON.stringify(userOfferObj),
    })
      .then((response) => response.json())
      .then((response) => getUserOffers())
      .catch((error) => {
        alert(error);
      });
  }

  //handle dropdown change
  function handleSelectChangePOSTuser(event) {
    setStateUserOfferUserID(event.value);
  }

  //handle dropdown change
  function handleSelectChangePOSToffer(event) {
    setStateUserOfferOfferID(event.value);
  }

  //handle dropdown change
  function handleSelectChangePOSTstatus(event) {
    setStateUserOfferStatus(event.target.value);
  }

  //logic to display userOffers

  const usersDropdownData = usersData.map((user, id) => {
    return {
      label: user.name,
      value: user.id,
    };
  });

  const offersDropdownData = offersData.map((offer, id) => {
    return {
      label: offer.title,
      value: offer.id,
    };
  });

  function listenerStateUserOfferStatus(status) {
    return status;
  }

  function handleSelectChangeUserOfferStatus(event) {
    var strId = event.target.id;
    var value = event.target.value;
    var id = strId.slice(strId.lastIndexOf("-") + 1);

    onSave(id, value);
  }

  //update useroffer
  function onSave(id, value) {
    var userofferObj = {};
    userofferObj.status = value;

    fetchData(process.env.REACT_APP_API_URL + "/useroffers/" + id, {
      method: "PUT",
      body: JSON.stringify(userofferObj),
    })
      .then((response) => response.json())
      .then((response) => getUserOffers());
  }

  function onDelete(id) {
    fetchData(process.env.REACT_APP_API_URL + "/useroffers/" + id, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((response) => getUserOffers());
  }

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const UserOffersTable = (props) => {
    const { items, requestSort, sortConfig } = useSortableData(
      props.userOffers
    );
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    return (
      <table className="min-w-full divide-y divide-gray-400 px-6 py-3">
        <thead className="">
          <tr>
            <th
              scope="col"
              className="w-3/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("user_id")}
                className={getClassNamesFor("user_id")}
              >
                Nutzer
              </button>
            </th>
            <th
              scope="col"
              className="w-5/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("offer_id")}
                className={getClassNamesFor("offer_id")}
              >
                Highlights
              </button>
            </th>
            <th
              scope="col"
              className="w-2/12 px-6 py-4 text-left text-xl font-medium text-gray-500"
            >
              <button
                type="button"
                onClick={() => requestSort("status")}
                className={getClassNamesFor("status")}
              >
                Status
              </button>
            </th>
            <th></th>
            <th className="w-1/12 px-6 py-4 text-left text-xl font-medium text-gray-500"></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {items
            .filter((itemData) => {
              if (searchTerm == "") {
                return itemData;
              } else if (
                usersData
                  .filter((p) => p.id === itemData.user_id)
                  .map((p) => p.name)
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                offersData
                  .filter((p) => p.id === itemData.offer_id)
                  .map((p) => p.title)
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              ) {
                return itemData;
              }
            })
            .map((useroffer, id) => (
              <tr key={"useroffer-" + id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {usersData
                    .filter((u) => u.id === useroffer.user_id)
                    .map((u) => u.name)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {offersData
                    .filter((o) => o.id === useroffer.offer_id)
                    .map((o) => o.title)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {
                    <select
                      className="block appearance-none w-full  border-none bg-gray-100 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                      id={"useroffer-status-" + useroffer.id}
                      onSelect={listenerStateUserOfferStatus}
                      onChange={handleSelectChangeUserOfferStatus}
                    >
                      {useroffer.status === "active" ? (
                        <>
                          <option value="active">Aktiv</option>
                          <option value="inactive">Inaktiv</option>
                        </>
                      ) : (
                        <>
                          <option value="inactive">Inaktiv</option>
                          <option value="active">Aktiv</option>
                        </>
                      )}
                    </select>
                  }
                </td>
                <td></td>
                <td className="px-6 py-4 whitespace-nowrap float-right w-full">
                  <Button onClick={() => onDelete(useroffer.id)} className="">
                    <FontAwesomeIcon icon={faTrashAlt} className="" />
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {errorState ? (
        <Section>
          <Container>
            <Alert type="secondary">
              Serverfehler! Etwas ist schief gelaufen. Informieren Sie einen
              Entwickler.
            </Alert>
          </Container>
        </Section>
      ) : (
        <div>
          {roleData === "admin" ? (
            <div className="">
              {mountedU && mountedO && mountedUO ? (
                <Section>
                  <Container>
                    <Modal open={isOpen} className="">
                      <ModalHeader
                        heading="Neue Zuordnung"
                        onClose={() => setIsOpen(false)}
                      />

                      <CardBody className="">
                        <form>
                          <div className="grid gap-4 w-96 mb-4">
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="user"
                              >
                                Nutzer
                              </label>
                              <Select
                                onMenuOpen={onUserMenuOpen}
                                onMenuClose={onUserMenuClose}
                                options={usersDropdownData}
                                onChange={handleSelectChangePOSTuser}
                                placeholder="Kunde..."
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="offer"
                              >
                                Highlight
                              </label>
                              <Select
                                onMenuOpen={onOfferMenuOpen}
                                onMenuClose={onOfferMenuClose}
                                options={offersDropdownData}
                                onChange={handleSelectChangePOSToffer}
                                placeholder="Highlight..."
                              />
                            </div>
                            <div className="">
                              <label
                                className="block tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="status"
                              >
                                Status
                              </label>
                              <select
                                className="appearance-none w-full text-gray-500 border-1 border-gray-300 rounded py-2 px-3 leading-tight"
                                name="status"
                                defaultValue={stateUserOfferStatus}
                                selected={stateUserOfferStatus}
                                onChange={handleSelectChangePOSTstatus}
                              >
                                <option value="inactive">Inaktiv</option>
                                <option value="active">Aktiv</option>
                              </select>
                            </div>
                          </div>
                          <Button
                            variant="primary"
                            type=""
                            className=""
                            onClick={postUserOffer}
                          >
                            Zuordnung anlegen
                          </Button>
                        </form>
                      </CardBody>
                    </Modal>
                    <div className="flex mb-2">
                      <div className="w-10/12">
                        <h1 className="text-4xl font-bold mb-8">
                          Sellies Highlights - Zuordnung&nbsp;
                        </h1>
                      </div>
                      <div className="w-2/12">
                        <Button onClick={() => setIsOpen(true)} className="">
                          <FontAwesomeIcon icon={faPlus} className="" />
                        </Button>
                      </div>
                    </div>
                    <div className="flex mb-8 flex-row-reverse">
                      <div className="w-2/12 ">
                        <input
                          type="text"
                          className="text-hpl bg-hpl_bg hover:bg-gray-300 hover:text-gray-800 border-none rounded w-full"
                          placeholder="Suche..."
                          onChange={(event) => {
                            setSearchTerm(event.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <UserOffersTable userOffers={userOffersData} />
                    </div>
                  </Container>
                </Section>
              ) : (
                <div>
                  <Container>Loading...</Container>
                </div>
              )}
            </div>
          ) : (
            <Section>
              <Container>
                <Alert type="secondary">
                  Auf diese Ansicht haben Sie keinen Zugriff!
                </Alert>
              </Container>
            </Section>
          )}
        </div>
      )}
    </div>
  );
}

export default SelliesHighlightsZuordnung;
