import "../App.css";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails } from "../helpers";
import CmpDashboard from "../components/CmpDashboard";
import { Container } from "../ui/Container";

import "../App.css";

function Uebersicht() {
  const [mounted, setMounted] = useState(false);
  const [projectsData, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState();
  useEffect(() => {
    getProjects();
  }, []);

  async function getProjects() {
    var data = [];
    if (getUserDetails()) {
      await fetchData(
        process.env.REACT_APP_API_URL +
          "/user/" +
          JSON.parse(localStorage.getItem("user-details"))["id"] +
          "/projects",
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (res) {
          for (var ii = 0; ii < res.projects.length; ii++) {
            data.push(res.projects[ii]);
          }
          setProjects(data);
          var cur = data[0];
          setCurrentProject(cur);
          setMounted(true);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
    }
  }

  const projectsDropdownData = projectsData.map((project, id) => {
    return (
      <option key={"project-dropdown-id-" + id} value={project.id}>
        {project.name}
      </option>
    );
  });

  //handle dropdown change
  function handleSelectChange(event) {
    setCurrentProject(
      projectsData.filter((element) => {
        return element.id === parseInt(event.target.value);
      })[0]
    );
  }

  return (
    <div className="pt-14">
      {mounted ? (
        <div>
          <Container>
            {projectsData !== undefined ? (
              <select
                className="rounded-md p-2.5 w-full bg-hpl_bg text-hpl font-bold hover:bg-gray-300 hover:text-gray-800 border-none text-center text-xl"
                name="project"
                defaultValue={currentProject.id}
                selected={currentProject.id}
                onChange={handleSelectChange}
              >
                {projectsDropdownData}
              </select>
            ) : (
              <>
                <p>Kein Projekt gefunden!</p>
              </>
            )}
          </Container>
          <CmpDashboard CurrentProject={currentProject} />
        </div>
      ) : (
        <div>
          <Container>Loading...</Container>
        </div>
      )}
    </div>
  );
}

export default Uebersicht;
