import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faStarHalf,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import {
  faStar as faStarEmpty,
} from "@fortawesome/free-regular-svg-icons";

export function Stars({ stars, max }) {
  
  const maxStarsCount = max || 5
  stars = Math.min(stars, maxStarsCount)

  const fullStarsCount = Math.floor(stars)
  const halfStarsCount = 0 // TODO
  const emptyStarsCount = maxStarsCount - (fullStarsCount + halfStarsCount)
  var starIndex = 1
  
  const fullStars = [...Array(fullStarsCount).keys()].map(star => {
    return <FontAwesomeIcon key={'star-' + starIndex++} icon={faStar} className="text-primary" />
  })
  
  const halfStars = [...Array(halfStarsCount).keys()].map(star => {
    return <FontAwesomeIcon key={'star-' + starIndex++} icon={faStarHalf} className="text-primary" />
  })

  const emptyStars = [...Array(emptyStarsCount).keys()].map(star => {
    return <FontAwesomeIcon key={'star-' + starIndex++} icon={faStarEmpty} className="text-primary" />
  })
  
  return <>
      { fullStars }
      { halfStars }
      { emptyStars }
    </>
}