import React, { useState, useEffect } from "react";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import CmpAddedValueGoogleAnalytics from "./CmpAddedValueGoogleAnalytics";
import CmpAddedValueEtracker from "./CmpAddedValueEtracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";

import "../App.css";
import "tippy.js/dist/tippy.css";

function CmpAddedValue({ CurrentProject }) {
  const [dateError, setDateError] = useState(false);
  const [startDate, setStartDate] = useState(CurrentProject.publication_date);
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  return (
    <div className="">
      <div>
        <Section>
          <Container>
            <SectionTitle>Im Zeitraum</SectionTitle>

            <div className="grid gap-4 grid-cols-3 mb-8">
              <div>
                <label
                  className="block tracking-wide text-gray-700 text-m font-bold mb-2"
                  htmlFor="timestamp_from"
                >
                  von
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-none bg-gray-100 rounded py-3 px-4 leading-tight"
                  name="timestamp_from"
                  type="date"
                  defaultValue={startDate}
                  onChange={(e) => {
                    if (Date.parse(startDate) > Date.parse(endDate)) {
                      setDateError(true);
                    }
                    setStartDate(e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  className="block tracking-wide text-gray-700 text-m font-bold mb-2"
                  htmlFor="timestam_until"
                >
                  bis
                </label>
                <input
                  className="appearance-none block w-full text-gray-700 border-none bg-gray-100 rounded py-3 px-4 leading-tight"
                  name="timestam_until"
                  type="date"
                  defaultValue={endDate}
                  onChange={(e) => {
                    if (Date.parse(startDate) > Date.parse(endDate)) {
                      setDateError(true);
                    }
                    setEndDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              {dateError ? (
                <div className="border-none bg-red-200 rounded py-3 px-4 mb-8">
                  <p className="font-semibold text-gray-700 align-middle p-2 w-full text-center">
                    Die von Ihnen gewählte Zeitspanne ist ungültig!
                  </p>
                  <div className="grid justify-items-center">
                    <div>
                      <button
                        className="rounded-md p-2 bg-red-500 text-white font-semibold text-gray-700 hover:bg-red-700 hover:text-white"
                        onClick={() => window.location.reload()}
                      >
                        <FontAwesomeIcon icon={faRedo} className="mr-4" />
                        Zurücksetzen
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            {CurrentProject.tiles.tracking_google_analytics ? (
              <CmpAddedValueGoogleAnalytics
                CurrentProject={CurrentProject}
                startDate={startDate}
                endDate={endDate}
              />
            ) : (
              <></>
            )}
            {CurrentProject.tiles.tracking_google_analytics &&
            CurrentProject.tiles.tracking_etracker ? (
              <hr className="my-12" />
            ) : (
              <></>
            )}
            {CurrentProject.tiles.tracking_etracker ? (
              <CmpAddedValueEtracker
                CurrentProject={CurrentProject}
                startDate={startDate}
                endDate={endDate}
              />
            ) : (
              <></>
            )}
          </Container>
        </Section>
      </div>
    </div>
  );
}
export default CmpAddedValue;
