import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { fetchData, getUserDetails } from "../helpers";
import { Button } from "../ui/Button";
import { Container } from "../ui/Container";
import { Section, SectionTitle } from "../ui/Section";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import logoBG from "../assets/hpl-logo-gray.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorState, setErrorState] = useState(false);

  useEffect(() => {
    if (getUserDetails()) {
      if (
        localStorage.getItem("user-info").substr(2, 7) &&
        localStorage.getItem("user-details").substr(2, 4)
      ) {
        history.push("/");
      }
    }
  }, [history]);

  var user = {};

  async function login() {
    let item = { email, password };
    var result;
    try {
      result = await fetchData(process.env.REACT_APP_API_URL + "/login", {
        method: "POST",
        body: JSON.stringify(item),
      });
      result = await result.json();
      localStorage.setItem("user-info", JSON.stringify(result));
      await fetchData(process.env.REACT_APP_API_URL + "/user", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => (user = data["user"]))
        .catch((error) => {
          console.log("req failed", error);
        });

      if (
        localStorage.getItem("user-info") === '{"error":"UnAuthorised"}' ||
        user === "undefined"
      ) {
        setErrorMessage("E-Mail oder Passwort ist nicht korrekt!");
        localStorage.clear();
        history.push("/login");
      } else if (
        !(localStorage.getItem("user-info").substring(2, 7) === "token")
      ) {
        window.alert(
          "Es ist ein Fehler aufgetreten. Bitte informieren Sie den Hoster der Webseite."
        );
        localStorage.clear();
        history.push("/");
      } else {
        localStorage.setItem("user-details", JSON.stringify(user));
        if (user.role === "user") {
          history.push("/");
          window.location.reload();
        } else if (user.role === "admin") {
          history.push("/");
          window.location.reload();
        }
      }
    } catch (e) {
      setErrorState(true);
      return <div></div>;
    }
  }

  function handleKeyPress(target) {
    if (target.charCode === 13) {
      login();
    }
  }

  return (
    <div className="w-full h-screen">
      {errorState ? (
        <div>
          <Section>
            <Container>
              <div className="grid justify-items-center">
                <div className="mb-8">
                  <SectionTitle>
                    Etwas ist schief gelaufen. Versuchen Sie es später noch
                    einmal.
                  </SectionTitle>
                </div>
                <div className="mb-4 w-3/5">
                  <Button onClick={() => window.location.reload()}>
                    <FontAwesomeIcon icon={faRedo} className="mr-4" />
                    Erneut versuchen
                  </Button>
                </div>
                <div className="w-3/5">
                  {" "}
                  <a href="mailto:hilfe@homepage-lieferanten.de">
                    <Button>Informieren Sie uns über das Problem.</Button>
                  </a>
                </div>
              </div>
            </Container>
          </Section>
        </div>
      ) : (
        <>
          <Section>
            <Container>
              <h1 className="text-5xl text-hpl mb-12">MyHPL 2.0</h1>
              <SectionTitle>Login</SectionTitle>
              <div className="text-red-900 bg-red-200 rounded-sm mb-1 px-4 w-4/12">
                {errorMessage}
              </div>
              {getUserDetails() ? (
                localStorage.getItem("user-info").substr(2, 7) === "token" &&
                localStorage.getItem("user-details").substr(2, 4) === "id" ? (
                  <div>
                    <h2>Sie sind bereits eingeloggt!</h2>
                    <Link to="/">
                      <Button>Zur Startseite</Button>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <div className="flex">
                      <div className="w-2/12">
                        <input
                          type="text"
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none block w-full text-gray-700 border-none bg-gray-100 rounded py-3 px-4 leading-tight"
                          placeholder="E-Mail"
                          onKeyPress={handleKeyPress}
                        />
                      </div>
                      <div className="w-2/12">
                        <input
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                          className="appearance-none block w-full text-gray-700 border-none bg-gray-100 rounded py-3 px-4 leading-tight"
                          placeholder="Passwort"
                          onKeyPress={handleKeyPress}
                        />
                      </div>{" "}
                    </div>
                    <div className="w-full">
                      <Button onClick={login}> Login </Button>{" "}
                    </div>
                  </div>
                )
              ) : (
                <div>
                  <div className="flex mb-4">
                    <div className="w-2/12 pr-4">
                      <input
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        className="appearance-none block w-full text-gray-700 border-none bg-gray-100 rounded py-3 px-4 leading-tight"
                        placeholder="E-Mail"
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                    <div className="w-2/12">
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full text-gray-700 border-none bg-gray-100 rounded py-3 px-4 leading-tight"
                        placeholder="Passwort"
                        onKeyPress={handleKeyPress}
                      />
                    </div>{" "}
                  </div>
                  <div className="w-4/12">
                    <Button onClick={login}> Login </Button>{" "}
                  </div>
                </div>
              )}
            </Container>
          </Section>{" "}
          <div className="relative ">
            <img
              src={logoBG}
              alt=""
              className="fixed h-80 w-auto bottom-10 right-10"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
