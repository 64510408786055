import classNames from "classnames"

export function Alert({ children, className, type }) {
  type = type || 'default'

  let styleClass = 'text-hpl bg-hpl_bg'

  if (type === 'danger') {
    styleClass = 'text-red-700 bg-red-200'
  } else if (type === 'secondary') {
    styleClass = 'text-gray-700 bg-gray-200'
  }
  
  return (
    <div className={classNames(['rounded-md py-2 px-6 w-full font-semibold', styleClass, className])}>
      {children}
    </div>
  );
}
