import React, { useState, useEffect } from "react";
import { fetchData, getRole } from "../../helpers";
import { Container } from "../../ui/Container";
import { Button } from "../../ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import "../../App.css";

function CmpProjectAdminTracking({ CurrentProject }) {
  const trackingTypes = [
    "SALES_CALLS",
    "LEADS",
    "UNIQUE_VISITORS",
    // "EMAILS",
    // "SHOP",
    "CUSTOM",
  ];

  var id = window.location.pathname.split("/")[2];
  const [roleData, setRole] = useState([]);
  const [project, setProject] = useState(CurrentProject);
  const [mountedTracking, setMountedTracking] = useState(true);
  const [stateProjectCustomPrices, setStateProjectCustomPrices] = useState(
    project.custom_prices
  );
  const [stateNewTrackingType, setStateNewTrackingType] =
    useState("UNIQUE_VISITORS");
  const [stateTrackingArray, setStateTrackingArray] = useState([]);
  const [errorTracking, setErrorTracking] = useState(false);

  useEffect(() => {
    getRole().then((result) => {
      setRole(result);
    });

    getTrackings();
  }, [id]);

  function postTracking(event) {
    event.preventDefault();
    var trackingObj = {};
    trackingObj.project_id = project.id;
    trackingObj.trackingtype = stateNewTrackingType;
    trackingObj.tracking_id = "-";
    trackingObj.headline = setHeadline(stateNewTrackingType);

    fetchData(process.env.REACT_APP_API_URL + "/projecttrackings", {
      method: "POST",
      body: JSON.stringify(trackingObj),
    })
      .then((response) => response.json())
      .then((response) => getTrackings());
  }

  function setHeadline(value) {
    switch (value) {
      case "SALES_CALLS":
        return "Vertriebsanrufe";
      case "EMAILS":
        return "E-Mails";
      case "LEADS":
        return "Leads";
      case "UNIQUE_VISITORS":
        return "Unique Visitors";
      case "SHOP":
        return "Shop";
      case "CUSTOM":
        return "Custom Headline";
      default:
        return value;
    }
  }

  function handleChangeProjectTracking(event) {
    var value = event.target.value;
    var id = event.target.id.split("-");
    //setHeadline(id, value);
    onSaveTracking(id[0], id[1], value);
  }

  function onSaveTracking(tracking_id, key, value) {
    var trackingObj = {};
    trackingObj[key] = value;

    fetchData(
      process.env.REACT_APP_API_URL +
        "/projects/" +
        id +
        "/projecttrackings/" +
        tracking_id,
      {
        method: "PUT",
        body: JSON.stringify(trackingObj),
      }
    )
      .then((response) => response.json())
      .then(function (data) {
        if (data.success) {
          setErrorTracking(false);
          return data;
        }
        setErrorTracking(true);
      })
      .then((response) => getTrackings());
  }

  function getTrackings() {
    var res = [];
    if (localStorage.getItem("user-info")) {
      fetchData(process.env.REACT_APP_API_URL + "/projecttrackings", {
        method: "GET",
      })
        .then((data) => data.json())
        .then(function (data) {
          for (var ii = 0; ii < data.data.length; ii++) {
            if (data.data[ii]["project_id"] === parseInt(id)) {
              res.push(data.data[ii]);
            }
          }
          setStateTrackingArray(res);
        })
        .catch((error) => {
          console.log("req failed", error);
        });
      setMountedTracking(true);
    }
  }

  function onDeleteTracking(id) {
    //TODO
    //delete custom data in custom_prices json
    fetchData(process.env.REACT_APP_API_URL + "/projecttrackings/" + id, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((response) => getTrackings());
  }

  function handleChangeProjectCustomPrices(event) {
    var eventArray = event.target.name.split(["-"], 3);
    if (eventArray[1] === "CUSTOM") {
      stateProjectCustomPrices[eventArray[1] + "-" + eventArray[2]] =
        parseFloat(event.target.value);
    } else {
      stateProjectCustomPrices[event.target.name.split(["-"], 2)[1]] =
        parseFloat(event.target.value);
    }
    onSave(event);
  }

  function handleChangeProjectCustomPricesDescriptions(event) {
    var eventArray = event.target.name.split(["-"], 3);
    if (eventArray[1] === "DESCR:CUSTOM") {
      stateProjectCustomPrices[eventArray[1] + "-" + eventArray[2]] =
        event.target.value;
    } else {
      stateProjectCustomPrices[event.target.name.split(["-"], 2)[1]] =
        event.target.value;
    }
    onSave(event);
  }

  //update Project function
  function onSave(event) {
    event.preventDefault();
    var projectObj = {};
    projectObj.custom_prices = stateProjectCustomPrices;

    fetchData(process.env.REACT_APP_API_URL + "/projects/" + project.id, {
      method: "PUT",
      body: JSON.stringify(projectObj),
    })
      .then((response) => response.json())
      .then((response) => event.preventDefault());
  }

  function getKey(trackingObj) {
    var key = "";
    if (trackingObj.trackingtype === "CUSTOM") {
      key = trackingObj.trackingtype + "-" + trackingObj.id;
    } else {
      key = trackingObj.trackingtype;
    }
    return key;
  }

  const trackingtypeDropdown = trackingTypes.map((trackingtype, id) => {
    return (
      <option
        key={"trackingtype-dropdown-" + trackingtype}
        value={trackingtype}
      >
        {trackingtype}
      </option>
    );
  });

  const trackingTable = stateTrackingArray.map((trackingObj) => {
    var trackingKey = getKey(trackingObj);
    return (
      <tr key={"tracking-row-" + trackingObj.id}>
        <td className="px-3 py-2">
          {" "}
          <select
            className="block appearance-none w-full bg-gray-100 border-none text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
            id={trackingObj.id + "-trackingtype"}
            defaultValue={trackingObj.trackingtype}
            selected={trackingObj.trackingtype}
            onBlur={handleChangeProjectTracking}
          >
            {trackingtypeDropdown}
          </select>
        </td>
        <td className="px-3 py-2">
          {trackingObj.trackingtype === "CUSTOM" ? (
            <input
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
              id={trackingObj.id + "-headline"}
              name="tracking_headline"
              type="text"
              placeholder="Tracking-Headline"
              defaultValue={trackingObj.headline}
              onBlur={handleChangeProjectTracking}
            />
          ) : (
            <input
              className="appearance-none block w-full text-gray-700 border-none bg-white rounded py-3 px-4 leading-tight"
              id={trackingObj.id + "-headline"}
              name="tracking_headline"
              type="text"
              placeholder={trackingObj.headline}
              defaultValue={trackingObj.headline}
              readOnly={true}
            />
          )}
        </td>
        <td className="px-3 py-2">
          {trackingObj.trackingtype === "UNIQUE_VISITORS" ? (
            <></>
          ) : (
            <input
              className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
              id={trackingObj.id + "-tracking_id"}
              name="tracking_id"
              type="text"
              placeholder="Tracking-ID"
              defaultValue={trackingObj.tracking_id}
              onBlur={handleChangeProjectTracking}
            />
          )}
        </td>
        <td className="px-3 py-2">
          <input
            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
            id={
              trackingObj.id +
              "-custom_price-" +
              trackingObj.trackingtype +
              "-" +
              trackingObj.id
            }
            name={
              "custom_price-" + trackingObj.trackingtype + "-" + trackingObj.id
            }
            type="number"
            step=".01"
            placeholder={stateProjectCustomPrices[trackingKey]}
            defaultValue={stateProjectCustomPrices[trackingKey]}
            onBlur={(e) => handleChangeProjectCustomPrices(e)}
          />
        </td>
        <td className="px-3 py-2">
          <input
            className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-2 px-3 leading-tight"
            id={
              trackingObj.id +
              "-custom_price-DESCR:" +
              trackingObj.trackingtype +
              "-" +
              trackingObj.id
            }
            name={
              "custom_price-DESCR:" +
              trackingObj.trackingtype +
              "-" +
              trackingObj.id
            }
            type="text"
            placeholder={stateProjectCustomPrices["DESCR:" + trackingKey]}
            defaultValue={stateProjectCustomPrices["DESCR:" + trackingKey]}
            onBlur={(e) => handleChangeProjectCustomPricesDescriptions(e)}
          />
        </td>
        <td className="px-3 py-2 w-full">
          <Button onClick={() => onDeleteTracking(trackingObj.id)} className="">
            <FontAwesomeIcon icon={faTrashAlt} className="" />
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <div className="">
      {roleData === "admin" ? (
        mountedTracking ? (
          <div>
            <div className="flex">
              <h3 className="text-2xl font-bold">
                Tracking über Google Analytics
              </h3>{" "}
            </div>
            {/* <span>
            Die Tracking-ID setzt sich aus der Ereigniskategorie und der
            Ereignisaktion zusammen:
            <br /> Ereigniskategorie;Ereignisaktion <br />
            <br />
            Für den Typ Unique-Visitors wird wird keine ID benötigt, da der Wert
            aus den Besucherzahlen von Google Analytics errechnet wird.
          </span> */}
            <div className="divide-gray-800">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-2/12"
                    >
                      Trackingtyp
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-2/12"
                    >
                      Tracking-Headline
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-3/12"
                    >
                      Tracking-ID
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-2/12"
                    >
                      Eingestellter Preis in €
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-4/12"
                    >
                      Beschreibung für Preis
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-2 text-left font-medium text-gray-500 uppercase tracking-wider w-1/12"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {trackingTable}
                </tbody>
              </table>
              <hr />
              {errorTracking ? (
                <div className="text-red-900 bg-red-200 rounded-sm -m-0.5 px-4">
                  <span className="">
                    Headline wurde schon verwendet! Bitte neue Headline wählen.
                  </span>
                </div>
              ) : (
                <></>
              )}
              <div className="grid grid-cols-4 gap-4 px-3 py-2 mb-8">
                <select
                  className="block appearance-none w-full bg-gray-100 border-none  py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
                  id={"new-trackingtype"}
                  defaultValue={stateNewTrackingType}
                  selected={stateNewTrackingType}
                  onChange={(e) => {
                    setStateNewTrackingType(e.target.value);
                  }}
                >
                  {trackingtypeDropdown}
                </select>
                <Button
                  onClick={(e) => {
                    postTracking(e);
                  }}
                >
                  Tracking hinzufügen
                </Button>
              </div>
            </div>
            <div className="mt-4 mb-4">
              <hr />
            </div>
          </div>
        ) : (
          <div>
            <Container>Loading...</Container>
          </div>
        )
      ) : (
        <>
          <h1>Auf diese Ansicht haben Sie keinen Zugriff!</h1>
        </>
      )}
    </div>
  );
}

export default CmpProjectAdminTracking;
