import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getUserDetails } from "./helpers";

function Protected(props) {
  let { Cmp, role, navigation } = props;

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [message, setMessage] = useState(
    "Sie haben keinen Zugriff auf diese Seite"
  );

  const history = useHistory();

  useEffect(() => {
    const userDetails = getUserDetails();

    if (userDetails === null) {
      setIsUserLoggedIn(false);
      return;
    }

    if (userDetails.role === null) {
      navigation.navigate("/login");
      return;
    } else if (role !== undefined && role !== userDetails.role) {
      return;
    }

    setIsUserLoggedIn(true);
  }, [history]);

  return isUserLoggedIn ? <Cmp /> : <div>{message}</div>;
}

export default Protected;
