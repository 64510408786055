import { Bar } from "react-chartjs-2";
import { Card, CardBody, CardAccessory } from "../ui/Card";
import React, { useState, useEffect } from "react";
import { fetchData } from "../helpers";
import { Container } from "../ui/Container";
import { SectionTitle } from "../ui/Section";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import "../App.css";
import { Alert } from "../ui/Alert";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { result } from "lodash";

function CmpAddedValueGraph({ CurrentProject }) {
  const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  const [addedValuesPerMonth, setAddedValuesPerMonth] = useState([]);
  const [customPrices] = useState(CurrentProject.custom_prices);
  const [mountedD, setMountedD] = useState(false);
  const [chartDataSet, setChartDataSet] = useState([]);

  const chartData = {
    labels: addedValuesPerMonth.map((addedV) => monthNames[addedV.monthIndex]),
    datasets: [
      {
        label: "Erwirtschafteter Mehrwert in Euro",
        data: chartDataSet,
        backgroundColor: ["rgba(0, 27, 190, .5)"],
        borderColor: ["rgba(0, 27, 190, 1.0)"],
        borderWidth: 2,
      },
    ],
  };

  useEffect(() => {
    try {
      getAddedValuesPerMonths();
    } catch {}
  }, []);

  function getAddedValuesPerMonths() {
    if (localStorage.getItem("user-info")) {
      fetchData(
        process.env.REACT_APP_API_URL +
          "/projects/" +
          CurrentProject.id +
          "/etracking-timeline",
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then(function (response) {
          setAddedValuesPerMonth(response.data);

          //get chart data
          var resultArray = [];
          for (var ii = 0; ii < response.data.length; ii++) {
            var monthAddedValue = 0;

            Object.entries(response.data[ii].data).forEach((tracking) => {
              monthAddedValue =
                monthAddedValue +
                tracking[1] *
                  customPrices[
                    "etracker_tracking-" + tracking[0].split("-")[0]
                  ];
            });
            resultArray.push(monthAddedValue);
          }
          setChartDataSet(resultArray);
          setMountedD(true);
        });
    }
  }

  return (
    <div>
      <SectionTitle>
        So entwickelt sich der Mehrwert im letzten halben Jahr
      </SectionTitle>
      <Card>
        <CardAccessory>
          <Tippy
            content={
              <div>
                Das Diagramm zeigt den Verlauf des erwirtschafteten Mehrwert des
                Projekts.
              </div>
            }
          >
            <div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="lg"
                className="text-hpl"
              />
            </div>
          </Tippy>
        </CardAccessory>
        <CardBody>
          {mountedD ? (
            <></>
          ) : (
            <div className="flex">
              <div className="flex">
                <Alert>Diagramm wird geladen...</Alert>
              </div>
            </div>
          )}
          <Bar data={chartData} options={{ responsive: true }} />
        </CardBody>
      </Card>
    </div>
  );
}
export default CmpAddedValueGraph;
