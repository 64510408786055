import "./App.css";
import Sidebar from "./components/Sidebar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Register from "./components/Register";
import Uebersicht from "./pages/Uebersicht";
import Bewertungen from "./pages/Bewertungen";
import Ihr_Mehrwert from "./pages/Ihr_Mehrwert";
//import Besucherzahlen from "./pages/Statistiken";
//import Ihre_Webseite from "./pages/--Ihre_Webseite";
import Startseite from "./pages/Startseite";
import Kunden from "./pages/admin/Kunden";
//import CmpCustomerAdmin from "./components/admin/CmpCustomerAdmin";
import Projekte from "./pages/admin/Projekte";
import CmpProjectAdmin from "./components/admin/CmpProjectAdmin";
import Produkte from "./pages/admin/Produkte";
import CmpProductAdmin from "./components/admin/CmpProductAdmin";
import ProdukteZuordnung from "./pages/admin/ProdukteZuordnung";
import SelliesHighlights from "./pages/admin/SelliesHighlights";
import SelliesHighlightsZuordnung from "./pages/admin/SelliesHighlightsZuordnung";
import CmpOfferAdmin from "./components/admin/CmpOfferAdmin";
import Protected from "./Protected";
import Header from "./components/Header";
import Digitalstrategien from "./pages/admin/Digitalstrategien";
import DigitalstrategienZuordnung from "./pages/admin/DigitalstrategienZuordnung";
import CmpDigitalstategyAdmin from "./components/admin/CmpDigitalstrategyAdmin";
import NotFound from "./components/CmpNotFound";
import CmpProjectPreview from "./components/admin/CmpProjectPreview";
import Statistiken from "./pages/Statistiken";
import Tickets from "./pages/Tickets";
import DigitalstrategieUndAngebote from "./pages/DigitalstrategieUndAngebote";
import Spenden from "./pages/Spenden";
import Vereine from "./pages/admin/Vereine";
import Bewertungsseiten from "./pages/admin/Bewertungsseiten";
import { CustomerDetail } from "./templates/CustomerDetail";
import Anleitungen from "./pages/admin/Anleitungen";
import Bewertungstool from "./pages/admin/anleitungen/Bewertungstool";

function App() {
  return (
    <div className="App text-font_body">
      <BrowserRouter>
        <div className="wrapper">
          <div className="app-sidebar bg-white border border-light-grey">
            <Sidebar />
          </div>
          <div className="app-main">
            <Header />
            <Switch>
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />

              <Route path="/anleitungen" exact>
                <Protected Cmp={Anleitungen} role="admin" />
              </Route>
              <Route path="/anleitungen/bewertungstool">
                <Protected Cmp={Bewertungstool} role="admin" />
              </Route>
              <Route path="/uebersicht">
                <Protected Cmp={Uebersicht} />
              </Route>
              <Route path="/bewertungen">
                <Protected Cmp={Bewertungen} />
              </Route>
              <Route path="/spenden">
                <Protected Cmp={Spenden} />
              </Route>
              <Route path="/ihr-mehrwert">
                <Protected Cmp={Ihr_Mehrwert} />
              </Route>
              {/* <Route path="/ihre-webseite">
                <Protected Cmp={Ihre_Webseite} />
              </Route> */}
              <Route path="/statistiken">
                <Protected Cmp={Statistiken} />
              </Route>
              <Route path="/tickets">
                <Protected Cmp={Tickets} />
              </Route>
              <Route path="/digitalstrategie-und-angebote">
                <Protected Cmp={DigitalstrategieUndAngebote} />
              </Route>
              <Route exact path="/kunden">
                <Protected Cmp={Kunden} />
              </Route>
              <Route path="/kunden/:id">
                <Protected Cmp={CustomerDetail} />
              </Route>
              <Route path="/projekte">
                <Protected Cmp={Projekte} />
              </Route>
              <Route exact path="/projekt/:id">
                <Protected Cmp={CmpProjectAdmin} />
              </Route>
              <Route exact path="/projectpreview/:id">
                <Protected Cmp={CmpProjectPreview} />
              </Route>
              <Route path="/produkte">
                <Protected Cmp={Produkte} />
              </Route>
              <Route exact path="/produkt/:id">
                <Protected Cmp={CmpProductAdmin} />
              </Route>
              <Route path="/produkte-zuordnung">
                <Protected Cmp={ProdukteZuordnung} />
              </Route>
              <Route path="/sellies-highlights">
                <Protected Cmp={SelliesHighlights} />
              </Route>
              <Route exact path="/sellies-highlight/:id">
                <Protected Cmp={CmpOfferAdmin} />
              </Route>
              <Route path="/sellies-highlights-zuordnung">
                <Protected Cmp={SelliesHighlightsZuordnung} />
              </Route>
              <Route path="/digitalstrategien">
                <Protected Cmp={Digitalstrategien} />
              </Route>
              <Route exact path="/digitalstrategie/:id">
                <Protected Cmp={CmpDigitalstategyAdmin} />
              </Route>
              <Route path="/digitalstrategie-zuordnung">
                <Protected Cmp={DigitalstrategienZuordnung} />
              </Route>
              <Route exact path="/">
                <Protected Cmp={Startseite} />
              </Route>
              <Route component={NotFound} path="*" />
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
