import { NavLink } from "react-router-dom";
import logo from "../logo.png";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faChartPie,
  faTicketAlt,
  faRoute,
  faDoorOpen,
  faUser,
  faList,
  faArrowLeft,
  faComment,
  faSitemap,
  faSignInAlt,
  faStar,
  faHeart,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { getUserDetails } from "../helpers";

function Sidebar() {
  const history = useHistory();
  var userDetails = getUserDetails();
  var menuItems = [
    {
      name: "Login",
      route: "/login",
      icon: <FontAwesomeIcon icon={faSignInAlt} className="" />,
    },
  ];

  if (userDetails && userDetails.role === "admin") {
    menuItems = [
      {
        name: "Kunden",
        route: "/kunden",
        icon: <FontAwesomeIcon icon={faUser} className="" />,
      },
      {
        name: "Projekte",
        route: "/projekte",
        icon: <FontAwesomeIcon icon={faSitemap} className="" />,
      },
      {
        name: "Produkte",
        route: "/produkte",
        icon: <FontAwesomeIcon icon={faList} className="" />,
      },
      {
        name: "Digitalstrategien",
        route: "/digitalstrategien",
        icon: <FontAwesomeIcon icon={faRoute} className="" />,
      },
      {
        name: "Sellies Highlights",
        route: "/sellies-highlights",
        icon: <FontAwesomeIcon icon={faComment} className="" />,
      },
      {
        name: "Anleitungen",
        route: "/anleitungen",
        icon: <FontAwesomeIcon icon={faBook} className="" />,
      },
      {
        name: "Abmelden",
        route: "/logout",
        icon: <FontAwesomeIcon icon={faDoorOpen} className="" />,
      },
    ];
  } else if (userDetails) {
    menuItems = [
      {
        name: "Ihr Mehrwert",
        route: "/ihr-mehrwert",
        icon: <FontAwesomeIcon icon={faChartLine} className="" />,
      },
      {
        name: "Statistiken",
        route: "/statistiken",
        icon: <FontAwesomeIcon icon={faChartPie} className="" />,
      },
      {
        name: "Ihre Tickets",
        route: "/tickets",
        icon: <FontAwesomeIcon icon={faTicketAlt} className="" />,
      },
      {
        name: "Digitalstrategie",
        route: "/digitalstrategie-und-angebote",
        icon: <FontAwesomeIcon icon={faRoute} className="" />,
      },
      {
        name: "Bewertungen",
        route: "/bewertungen",
        icon: <FontAwesomeIcon icon={faStar} />,
      },
      {
        name: "Spenden",
        route: "/spenden",
        icon: <FontAwesomeIcon icon={faHeart} />,
      },
      { name: "Ihr Mehrwert", route: null },
      { name: "Ihre Website", route: null },
      { name: "Ihre SEO", route: null },
      { name: "Ihr GoogleMyBusiness", route: null },
      { name: "Ihre Referenz auf Google", route: null },
      { name: "Ihr Google Ads", route: null },
      { name: "Ihre Newsletter", route: null },
      { name: "Ihre Automatisierungen", route: null },
      { name: "Ihre Tickets", route: null },
      { name: "Login in Webseite", route: null },
      { name: "Häufige Fragen", route: null },
      { name: "Routine Termin", route: null },
      { name: "Marketing Ideen", route: null },
      { name: "Status Digitalstrategie", route: null },
      { name: "Unsere Partner", route: null },
      { name: "Verträge verwalten", route: null },
      { name: "Mehrwert einstellen", route: null },
      {
        name: "Abmelden",
        route: "/logout",
        icon: <FontAwesomeIcon icon={faDoorOpen} className="" />,
      },
    ];
  }

  return (
    <div className="text-font_menu h-screen">
      <div className="flex px-5 mt-4 mb-8">
        <div className="w-1/6">
          <button
            className="justify-center rounded-md border-none text-2xl h-full align-middle pl-3 w-full"
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="" />
          </button>
        </div>
        <div className="w-5/6">
          <NavLink to="/">
            <div className="p-5 w-full flex justify-center">
              <img src={logo} alt="" className="h-10 w-auto justify-center" />
            </div>
          </NavLink>
        </div>
      </div>

      <div className="flex flex-col px-5">
        {menuItems
          .filter((menuItem) => menuItem.route !== null)
          .map((menuItem) => {
            return (
              <NavLink
                className="text-center rounded-md p-3 mb-2 whitespace-nowrap hover:bg-gray-200 hover:text-gray-500 font-semibold"
                activeClassName="bg-hpl_bg text-hpl rounded-md p-3 mb-2"
                to={menuItem.route}
                key={"navbar-key-" + menuItem.name}
              >
                <div className="flex">
                  <div className="w-1/6">{menuItem.icon}</div>
                  <div className="w-5/6">{menuItem.name}</div>
                </div>
              </NavLink>
            );
          })}
      </div>
    </div>
  );
}

export default Sidebar;
