import { Button } from "../ui/Button";
import { Section } from "../ui/Section";
import { Container } from "../ui/Container";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  CardFooter,
} from "../ui/Card";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../App.css";

function Startseite() {
  const [userDetails, setUserDetails] = useState([]);
  useEffect(() => {
    getUserDetails();
  }, []);

  function getUserDetails() {
    if (localStorage.getItem("user-info")) {
      setUserDetails(JSON.parse(localStorage.getItem("user-details")));
    }
  }

  const Panels = ({ userDetails }) => {
    if (userDetails.role === "admin") {
      return (
        <Section>
          <Container>
            <h1 className="text-4xl mb-8 font-bold">
              Willkommen, {userDetails.name}!
            </h1>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    {" "}
                    <CardTitle>Kunden</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/kunden">
                      <Button variant="primary">Zur Kundenübersicht</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Projekte</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/projekte">
                      <Button variant="primary">Zur Projektübersicht</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Produkte</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/produkte">
                      <Button variant="primary">Zu den Produkten</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Digitalstrategien</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/digitalstrategien">
                      <Button variant="primary">
                        Zu den Digitalstrategien
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Sellie</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/sellies-highlights">
                      <Button variant="primary">Zu Sellies Highlights</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Container>
        </Section>
      );
    } else {
      return (
        <Section>
          <Container>
            <h1 className="text-4xl mb-8 font-bold">
              Willkommen, {userDetails.name}!
            </h1>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle className="px-5">Ihr Mehrwert</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>Einblick in den Mehrwert, der in Ihrem gewünschten Zeitraum generiert wurde.</CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/ihr-mehrwert">
                      <Button variant="primary">Zu Ihrem Mehrwert</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Statistiken</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>Überblick zu Ihren Besuchern und Keywords.<br></br> <br></br></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/statistiken">
                      <Button variant="primary">Zu den Statsitiken</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Tickets</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>Hier erreichen Sie unseren Support<br></br><br></br><br></br></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/tickets">
                      <Button variant="primary">Zu den Tickets</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Digitalstrategie</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>Hier können Sie die für Sie kreierten Strategien und Angebote einsehen.<br></br><br></br></CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/digitalstrategie-und-angebote">
                      <Button variant="primary">
                       Digitalstrategie & Angebote
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Bewertungen</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>
                      Hier können Sie Ihre Bewertungen einsehen.<br></br><br></br>                     
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/bewertungen">
                      <Button variant="primary">
                       Bewertungen
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
              <div className="">
                <Card className="">
                  <CardHeader className="">
                    <CardTitle>Spenden</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <CardText>
                      Weitere Erklärungen folgen noch.<br></br><br></br><br></br>
                    </CardText>
                  </CardBody>
                  <CardFooter>
                    <Link to="/spenden">
                      <Button variant="primary">
                        Spenden
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            </div>
          </Container>
        </Section>
      );
    }
  };

  return (
    <div className="">
      <Panels userDetails={userDetails} />
    </div>
  );
}

export default Startseite;
